@import "plugins/normalize";

@import "utils/fonts";

@import "utils/vars";

@import "mixins/mixins";

//@import "plugins/grid-mixins";

@import "plugins/hystmodal.min";
// hystmodal fix
.hystmodal__window form { height: 100% !important; }

//@import "../../node_modules/tom-select/dist/scss/tom-select";
//@import "../../node_modules/tom-select/dist/scss/tom-select.default";
@import "plugins/accordion.min";
@import "plugins/mmenu";
//@import "../../node_modules/mmenu-js/dist/mmenu";
@import "plugins/swiper.min";

@import "utils/common";
@import "utils/btn";

@import "components/about-icons.scss";
@import "components/about-img.scss";
@import "components/about-list.scss";
@import "components/about-team.scss";
@import "components/article.scss";
@import "components/blog-filter.scss";
@import "components/blog-interested.scss";
@import "components/blog-item.scss";
@import "components/blog-popular.scss";
@import "components/blog.scss";
@import "components/burger.scss";
@import "components/contacts-page.scss";
@import "components/contacts.scss";
@import "components/education-feature.scss";
@import "components/education-list.scss";
@import "components/education-site.scss";
@import "components/event-form.scss";
@import "components/event-list.scss";
@import "components/faq.scss";
@import "components/features.scss";
@import "components/field-checkbox.scss";
@import "components/field-file.scss";
@import "components/field-num.scss";
@import "components/field-text.scss";
@import "components/filter.scss";
@import "components/footer-list.scss";
@import "components/footer.scss";
@import "components/header-menu.scss";
@import "components/header.scss";
@import "components/hero-about.scss";
@import "components/hero-blog.scss";
@import "components/hero-canvas.scss";
@import "components/hero-education.scss";
@import "components/hero-event.scss";
@import "components/hero-industries.scss";
@import "components/hero-list.scss";
@import "components/hero-portfolio.scss";
@import "components/hero-services.scss";
@import "components/hero-site.scss";
@import "components/hero-vacancies.scss";
@import "components/industries-list.scss";
@import "components/interested-slide.scss";
@import "components/main-blog.scss";
@import "components/main-clients.scss";
@import "components/main-hero.scss";
@import "components/main-platforms.scss";
@import "components/main-reviews.scss";
@import "components/main-tabs.scss";
@import "components/main-team.scss";
@import "components/menu-industry.scss";
@import "components/menu-services.scss";
@import "components/mobile-menu.scss";
@import "components/modal.scss";
@import "components/pagination.scss";
@import "components/portfolio-detail.scss";
@import "components/social.scss";
@import "components/swiper-general.scss";
@import "components/tabs.scss";
@import "components/tariff.scss";
@import "components/vacancies-expert.scss";
@import "components/vacancies-list.scss";
@import "components/vacancies-office.scss";
@import "components/vacancies-study.scss";
@import "components/vacancies-worker.scss";
@import "components/vacancy-another.scss";
@import "components/vacancy-career.scss";
@import "components/vacancy-cost.scss";
@import "components/vacancy.scss";

// fixes
.email_www { display: none; }
.text-page a, .text-page p { color: #333; }
.text-page a:hover { color: #000; text-decoration: underline; }
.text-page p { margin: 0px 0px 10px 0px; }

.portfolio-detail__content-text ul, 
.services-cost__big-text ul, 
.hero-site__text ul { list-style: square; margin: 0px 0px 0px 20px; }
.portfolio-detail__content-text ul li, 
.services-cost__big-text ul li, 
.hero-site__text ul li { margin: 0px 0px 5px 0px; }
.services-cost__big-text ul li a, 
.hero-site__text ul li a { color: white; text-decoration: underline; }

.tabs--main-tabs .tabs__link:hover, 
.swiper-pagination-bullet:hover { color: #fff;}
.main-platforms__link:hover, 
.main-blog__link:hover { opacity: 0.9; }
.post.section p { margin: 0px 0px 20px 0px; }
.post.section h2, .post.section h3, .post.section h4 { margin: 15px 0px 20px 0px; }
.post.section a { color: #2C4C9A; font-size: initial; }
.post.section a:Hover { text-decoration: underline; }
.post.section .blog-item__tag { border: #2C4C9A solid; padding: 3px 5px; display: inline-block; margin: 2px 0px; }
.post.section .blog-item__tag:hover { background-color: #2C4C9A; color: #fff; }
.hero-education.section ul { list-style: square; margin: 0px 0px 0px 16px; }

ul.about-count__list,
ul.services-stack__list, 
ul.services-detail-descr__apps { list-style: none; clear: both; margin: 20px 0px; }
ul.about-count__list li,
ul.services-stack__list li, 
ul.services-detail-descr__apps li { float: left; }
ul.services-stack__list li:last-child, 
ul.services-detail-descr__apps li:last-child { float: none; }
ul.about-count__list li.about-count__item { width: 33%; margin: 0px 0px 20px 0px; }
ul.about-count__list li.about-count__item img { margin: 0px 0px 10px 0px; }
@media (max-width: 768px) { ul.about-count__list li.about-count__item { width: 100%; margin: 0px 0px 10px 0px; } }

.services-cost__big-text p, .services-cost__big-text h3 { margin: 0px 0px 10px 0px; }
.services-detail-descr__subtitle { margin: 10px 0px; }

.services-cost__big-text a { color: #2C4C9A; }
.hero-list .ac .ac-trigger:hover { text-decoration: underline; }
.client_name { font-size: 2rem; color: #ff5c52; }
