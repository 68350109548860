.event-list{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-gap: 20px;
        margin-bottom: 32px;

        @include min($screen-md){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 40px 16px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            grid-gap: 53px 16px;
        }
    }

    &__link{
        color: $black;
    }

    &__img{
        position: relative;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 20px;
        }

        @include min($screen-xl){
            margin-bottom: 24px;
        }

        &:before{
            content: '';
            display: block;
            padding-top: 50%;
        }

        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__subtitle{
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.3px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 18px;
            letter-spacing: -0.36px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 16px;
            letter-spacing: -0.16px;
        }
    }

    &__date{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        color: $blue;

        @include min($screen-xl){
            font-size: 17px;
            letter-spacing: -0.68px;
        }
    }
}
