.blog-filter{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 24px;

    @include min($screen-md){
        margin-bottom: 40px;
    }

    @include min($screen-xl){
        margin-bottom: 56px;
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }


    /* Accordion style */
    .ac{
        background-color: $gray-lighter;
        border: none;
        border-bottom: 1px solid $black-light;
        color: $gray-lighter;
        margin-top: 0;

        &:last-child{
            border-bottom: none;
        }

        @include min($screen-md){
            border-bottom: none;
            background-color: transparent;
        }
    }

    .ac .ac-header{
        @include min($screen-md){
            display: none;
        }
    }

    .ac .ac-trigger{
        font-family: $hoves;
        font-size: 12px;
        font-weight: 500;
        line-height: 1;
        color: $gray;
        letter-spacing: -0.48px;
        padding-left: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .ac .ac-trigger::after{
        content: '';
        background-image: svg-load('arrow-down.svg', color=$gray);
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        transform-origin: center;
        //transition: $transition-time;
        width: 15px;
        height: 15px;
    }
    .ac.is-active > .ac-header .ac-trigger::after{
        content: '';
        transform: translate(0, -50%) rotate(180deg);
    }

    .ac .ac-panel{
        @include min($screen-md){
            overflow: visible;
        }
    }

    .ac .ac-panel .ac-text{
        padding: 18px 0 18px 12px;
        font-family: $hoves;

        @include min($screen-md){
            padding: 0;
        }

        #{$block-name}__item{
            margin-right: 4px;
            margin-bottom: 6px;

            @include min($screen-md){
                margin-right: 8px;
                margin-bottom: 0;
            }

            @include min($screen-xl){
                margin-right: 16px;
            }

        }

        #{$block-name}__link{
            display: block;
            font-size: 12px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.12px;
            padding: 3px 4px;
            color: $black;
            transition: background $transition-time;

            @include min($screen-md){
                font-size: 11px;
                line-height: 100%;
                letter-spacing: -0.33px;
                padding: 5px 6px;
            }

            @include min($screen-xl){
                padding: 8px;
                font-size: 20px;
                line-height: 110%;
                letter-spacing: -0.4px;
            }

            &--active{
                background-color: $red;
            }

            &:hover, &:focus-visible{
                background-color: $red;
            }
        }

    }
    /* end accordion style */
}
