.footer {
    $block-name: &; // #{$block-name}__element

    background-color: $black;

    padding: 50px 0;

    @include min($screen-md){
        padding: 64px 0;
    }

    @include min($screen-xl){
        padding: 48px 0;
    }

    &__wrapper{
        @include min($screen-md){
            display: grid;
            grid-template-areas:
            'menu menu menu'
            'logo social contacts'
            'logo social policy';
        }

        @include min($screen-xl){
            grid-template-areas:
            'menu menu menu'
            'logo social contacts'
            'policy policy policy';
            grid-template-columns: 1fr auto auto;
        }
    }

    &__logo{
        grid-area: logo;
        display: inline-block;
        margin-bottom: 25px;


        @include min($screen-md){
            margin-bottom: 0;
        }

        img{
            width: 110px;

            @include min($screen-md){
                width: 150px;
            }
        }
    }

    &__menu{
        grid-area: menu;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 10px;
        margin-bottom: 32px;

        @include min($screen-md){
            grid-template-columns: minmax(185px, 1fr) 1fr 1fr 1fr 1fr;
            grid-column-gap: 15px;
            margin-bottom: 51px;
        }

        @include min($screen-xl){
            grid-template-columns: minmax(295px, 1fr) 1fr 1fr 1fr 1fr;
            margin-bottom: 78px;
        }
    }


    &__list{
        &--desktop{
            display: none;

            @include min($screen-md){
                display: block;
            }
        }

        &--mobile{
            grid-column: 2/3;
        }
    }

    &__social{
        grid-area: social;
        margin-bottom: 32px;
    }

    &__social-link{
        display: inline-block;
        margin-right: 9px;

        @include min($screen-md){
            width: 48px;
            height: 48px;
            margin-right: 14px;
        }

        @include min($screen-xl){
            width: 61px;
            height: 61px;
        }

        &:last-child{
            margin-right: 0;
        }

        svg{
            width: 100%;
            height: 100%;
        }
    }

    &__contacts{
        grid-area: contacts;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            margin-left: 110px;
            margin-bottom: 13px;
        }
    }

    &__contacts-text{
        color: $white;
        padding: 5px 0;
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;

        @include min($screen-md){
            padding: 6px 0;
        }

        @include min($screen-xl){
            font-size: 17px;
            padding: 9px 0;
        }
    }

    &__policy{
        grid-area: policy;
        color: $white;
        opacity: 0.6;
        font-size: 11px;

        @include min($screen-xl){
            font-size: 14px;
        }

        a {
            color: #ffffff;
            &:hover {
                text-decoration: underline;
            }
        }

    }
}
