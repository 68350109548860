.education-site{
    $block-name: &; // #{$block-name}__element

    &__top{
        display: flex;
        align-items: flex-start;
        gap: 50px;

        @include min($screen-xl){
            gap: 70px;
        }
    }

    &__btn{
        flex-shrink: 0;
        width: 100%;

        @include min($screen-md){
            width: auto;
        }

        @include min($screen-xl){
            min-width: 250px;
        }

        &--mobile{
            @include min($screen-md){
                display: none;
            }
        }

        &--desktop{
            display: none;

            @include min($screen-md){
                display: block;
            }
        }
    }

    &__list{
        display: grid;
        grid-gap: 16px;
        margin-bottom: 24px;

        @include min($screen-md){
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 0;
        }
    }

    &__item{
        position: relative;
        display: flex;
        flex-direction: column;

        &:before{
            content: '';
            display: block;
            padding-top: 75%;

            @include min($screen-md){
                padding-top: 77%;
            }

            @include min($screen-xl){
                padding-top: 56%;
            }
        }

        &--white{
            color: $white;
        }
    }

    &__item-wrap{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 16px 16px 0;

        @include min($screen-md){
            padding: 24px 24px 0;
        }

        @include min($screen-xl){
            padding: 30px 30px 0;
        }

        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__subtitle{
        position: relative;
        z-index: 1;
        margin-bottom: auto;
        max-width: 430px;
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }
}
