.hero-portfolio{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    background-color: $gray-lighter;
    //color: $gray-lighter;
    padding-top: 300px;
    margin-bottom: 50px;

    //background-image: url(../img/pic-1.png), url(../img/pic-1.png);
    //background-repeat: no-repeat, no-repeat;
    //background-origin: border-box;
    ////background-position: 15% -35%, 80% 160%;
    //background-position: 300px -35%, 80% 160%;
    //background-size: 570px auto;

    @include min($screen-md){
        padding-top: 325px;
    }

    &:before{
        content: '';
        position: absolute;
        right: -700px;
        top: -800px;
        z-index: 0;
        background-image: url(../img/pic-2.svg);
        background-repeat: no-repeat;
        background-origin: border-box;
        width: 1130px;
        height: 1130px;
        background-size: contain;

        @include min($screen-md){
            width: 1670px;
            height: 1670px;
            right: -900px;
            top: -1100px;
        }

        @include min($screen-xl){
            right: -550px;
            top: -1100px;
        }
    }

    &__container{
        position: relative;
        z-index: 1;
    }

    //&__name{
    //    font-size: 17px;
    //    font-weight: 500;
    //    line-height: 100%;
    //    letter-spacing: -0.34px;
    //    color: $blue-light;
    //    margin-bottom: 24px;
    //
    //    @include min($screen-md){
    //        font-size: 21px;
    //        font-weight: 500;
    //        line-height: 100%;
    //        letter-spacing: -0.42px;
    //        margin-bottom: 40px;
    //    }
    //
    //    @include min($screen-xl){
    //        font-size: 24px;
    //        font-weight: 500;
    //        line-height: 120%;
    //        letter-spacing: -0.24px;
    //    }
    //}
    &__name{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        color: $blue-light;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.24px;
        }
    }

    &__name-gray{
        color: $gray;

        &:after{
            content: '/';
            margin-left: 5px;
            margin-right: 5px;
        }

        &:hover, &:focus-visible{
            color: $blue-light;
        }
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 76px;

        @include min($screen-md){
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 84px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 120px;
        }
    }

    &__subtitle{
        color: $gray;
        font-size: 14px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.56px;
        margin-bottom: 16px;

        @include min($screen-md){
            font-size: 17px;
            letter-spacing: -0.68px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            margin-bottom: 32px;
        }
    }

    &__wrapper{
        @include min($screen-md){
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 24px;
        }
    }

    &__col{
        margin-bottom: 18px;

        &:last-child{
            margin-bottom: 0;
        }

        @include min($screen-md){
            margin-bottom: 0;
        }
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        margin-left: -6px;

        @include min($screen-md){
            flex-direction: column;
        }
    }

    &__item{
        @include min($screen-md){
            margin-bottom: 10px;
        }

        @include min($screen-xl){
            margin-bottom: 14px;
        }

    }

    &__link{
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.12px;
        color: $black;
        padding: 6px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 22px;
            line-height: 100%;
            letter-spacing: -0.44px;
        }

        &:hover, &:focus-visible{
            background-color: $red;
        }
    }

    &__img{

        img{
            width: 100%;
            max-width: none;
        }
    }

    &__content{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
        }

        @include min($screen-xl){
            grid-template-columns: 610px 1fr;
        }
    }

    &__content-title{
        color: $gray-lighter;
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.34px;

        a { color: #fff; text-decoration: underline; }
        a:hover { text-decoration: none; }

        @include min($screen-xl){
            font-size: 30px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -1.2px;
        }
    }

    &__content-link{
        display: block;
        color: $gray-lighter;
        font-size: 12px;
        letter-spacing: -0.33px;
        padding: 12px 0;

        @include min($screen-xl){
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.18;
            letter-spacing: -0.32px;
            padding: 24px 0;
        }

        //&:hover, &:focus-visible{
        //    color: $gray;
        //}
    }

    &__content-text{
        font-size: 16px;
        color: $gray-lighter;

        @include min($screen-xl){
            font-size: 19px;
        }

        p + p{
            margin-top: 15px;
        }
    }

    &--detail{
        background-color: $blue;

        &:before{
            display: none;
        }

        #{$block-name}__name{
            color: $white;
            font-size: 17px;
            font-weight: 400;
        }

        #{$block-name}__name-gray{
            color: $white;
        }

        #{$block-name}__title{
            max-width: 750px;
            color: $white;
        }

        #{$block-name}__wrapper{
            display: block;
        }
    }
}
