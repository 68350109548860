.footer-list{
    $block-name: &; // #{$block-name}__element

    &__link{
        position: relative;
        display: inline-block;
        padding: 4px 0;
        color: $white;
        font-size: 12px;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 13px;
            max-width: 120px;
            padding: 6px 0;
        }

        @include min($screen-xl){
            font-size: 16px;
            letter-spacing: -0.32px;
            text-transform: lowercase;
            padding: 9px 0;
        }

        &:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            background-color: $white;
            transition: width $transition-time ease-out;
        }

        &:hover, &:focus-visible{
            &:after{
                width: 100%;
            }
        }

        &--first{
            display: none;
            color: $gray;

            @include min($screen-md){
                display: inline-block;
                max-width: 100%;
                margin-bottom: 4px;
            }

            @include min($screen-xl){
                margin-bottom: 6px;
            }
        }
    }

    &--first{
        #{$block-name}__link{
            font-size: 16px;

            @include min($screen-xl){
                font-size: 19px;
                text-transform: initial;
                padding: 8px 0;
            }
        }
    }

    &--another{
        margin-top: -23px;

        @include min($screen-md){
            display: none;
        }

        #{$block-name}__link{
            font-size: 11px;
            color: $gray;
            font-weight: 500;
            line-height: 1;

            &--first{
                display: inline-block;
                font-size: 12px;
                font-weight: 400;
                color: $white;
                margin-bottom: 8px;
            }
        }
    }
}
