.pagination {
    $block-name: &; // #{$block-name}__element
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__item {
        display: block;
        text-decoration: none;
        text-align: center;
        color: $gray;
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.36px;
        text-transform: lowercase;
        padding-left: 8px;
        padding-right: 8px;

        @include min($screen-md){
            font-size: 17px;
            letter-spacing: -0.68px;
        }

        & + & {
            border-left: 0;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }

        @at-root a#{&} {

            &:hover, &:focus-visible{
                color: $blue;
            }
        }

        @at-root span#{&} {
            cursor: default;
        }

        &--active {
            //color: $black;
            color: $blue;
        }
    }

    &--blog{
        @include min($screen-xl){
            justify-content: flex-start;
        }
    }
}
