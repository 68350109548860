.main-team{
    $block-name: &; // #{$block-name}__element

    background-color: $black;

    overflow: hidden;

    padding: 56px 0;

    @include min($screen-md){
        padding: 60px 0;
    }

    @include min($screen-xl){
        padding: 100px 0;
    }

    &__title{
        color: $gray-lighter;
    }

    &__list{
        margin-bottom: 48px;

        @include min($screen-md){
            display: flex;
            flex-wrap: nowrap;
            width: calc(465px * 3);
            margin-bottom: 55px;
        }

        @include min($screen-xl){
            //width: calc(670px * 3);
            width: 2118px;
            margin-bottom: 80px;
        }
    }

    &__item{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        &:last-child{
            margin-bottom: 0;

            &:after{
                display: none;
            }
        }

        @include min($screen-md){
            width: 465px;
            margin-bottom: 0;
            flex-direction: row;
            //justify-content: space-around;

        }

        &--first{
            color: $blue-light;

            @include min($screen-xl){
                width: 659px;
            }
        }

        &--second{
            color: $red;

            @include min($screen-xl){
                width: 793px;
            }
        }

        &--third{
            color: $white;

            @include min($screen-xl){
                width: 666px;
            }

            #{$block-name}__img{
                @include min($screen-xl){
                    width: 389px;
                }
            }
        }

        &:after{
            content: '+';
            font-size: 66px;
            font-weight: 400;
            line-height: 0.9;
            letter-spacing: -3.3px;
            margin-left: 24px;
            margin-right: 24px;

            @include min($screen-xl){
                margin-left: 32px;
                //margin-right: 32px;
                font-size: 71px;
            }
        }
    }

    &__img{
        width: 170px;

        @include min($screen-md){
            width: 260px;
        }

        @include min($screen-xl){
            width: 314px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__text{
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.34px;

        @include min($screen-md){
            font-size: 26px;
            line-height: 0.96;
            letter-spacing: -0.52px;
            margin-left: 22px;
        }

        @include min($screen-xl){
            font-size: 50px;
            line-height: 94%;
            letter-spacing: -2.5px;
            margin-left: 48px;
        }

        br{
            @include max($screen-md){
                display: none;
            }
        }
    }

    .scroller__inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        @include min($screen-md){
            gap: 24px;
        }

        @include min($screen-xl){
            gap: 50px;
        }

        img{
            max-width: 100%;
            height: 100%;
            object-fit: contain;
            max-height: 50px;
            min-width: 100px;
            filter: brightness(0) invert(1);
            opacity: 0.75;
            &:hover {
                filter: brightness(0) invert(1);
                opacity: 1;
            }
        }
    }

    .scroller[data-animated="true"] {
        overflow: hidden;
        //-webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
        //mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
    }

    .scroller[data-animated="true"] .scroller__inner {
        width: max-content;
        flex-wrap: nowrap;
        animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
    }

    .scroller[data-direction="right"] {
        --_animation-direction: reverse;
    }

    .scroller[data-direction="left"] {
        --_animation-direction: forwards;
    }

    .scroller[data-speed="fast"] {
        --_animation-duration: 20s;
    }

    .scroller[data-speed="slow"] {
        --_animation-duration: 60s;
    }

    @keyframes scroll {
        to {
            transform: translate(calc(-50% - 0.5rem));
        }
    }

}
