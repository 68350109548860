.header {
    $block-name: &; // #{$block-name}__element

    position: absolute;
    left: 0;
    top: 20px;
    width: 100%;
    z-index: 10;

    @include min($screen-md){
        top: 24px;
    }

    &__wrapper{
        display: flex;
        justify-content: space-between;
    }

    &__logo{
        width: 70px;
        display: flex;
        align-items: center;

        @include min($screen-md){
            margin-right: 53px;
        }

        @include min($screen-xl){
            margin-right: 106px;
            width: 90px;
        }
    }

    &__menu{
        display: none;
        flex-grow: 1;

        @include min($screen-md){
            display: flex;
            align-items: center;
        }
    }

    &__button{
        display: none;

        @include min($screen-md){
            display: block;
        }

        .btn__up{
            text-align: center;
            line-height: 1.18;
            padding: 8px 12px;
            font-weight: 400;
            font-size: 11px;
            transition: $transition-time;

            @include min($screen-xl){
                padding: 20px 32px;
                text-transform: lowercase;
                font-size: 16px;
            }
        }
    }

    &__burger{
        @include min($screen-md){
            display: none;
        }
    }

    &--dark{
        .header-menu__link{
            color: $black;

            &:after{
                background-color: $black;
            }
        }

        .burger__icon{
            span{
                &:before, &:after{
                    background-color: $black;
                }
            }
        }
    }
}
