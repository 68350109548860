.field-text {

    $block-name: &; // #{$block-name}__element

    display: block;
    margin-bottom: 20px;

    @include min($screen-xl){
        margin-bottom: 32px;
    }

    &__name {
        @include field-name;
    }

    &__input-wrap {
        display: block;
    }

    &__input {
        @include field-text;

        @at-root textarea#{&} {
            //$textarea-height: ($line-height * 2) + ($field-padding-vertical * 2);
            //
            height: 39px;
            //min-height: calc(#{$textarea-height} + 2px);
            resize: vertical;
        }
    }

    &__help-text {
        @include field-help-text;
    }


    &--error {

        #{$block-name}__name,
        #{$block-name}__input,
        #{$block-name}__help-text {
            color: $red;

            &::placeholder{
                color: $red;
            }
        }

        #{$block-name}__input {
            border-color: $red;
            //background-color: lighten($red, 35%);
        }
    }

    &--label{
        position: relative;
        overflow: hidden;
        margin: 0;

        #{$block-name}__name{
            position: absolute;
            margin: 0;
            font-weight: 400;
            line-height: 1.3;
            font-size: 18px;
            top: 13px;
            left: 15px;
            transition: transform 250ms;
            transition-delay: 50ms;
            pointer-events: none;
            transform-origin: left;
            color: $black;
            white-space: nowrap;
        }

        #{$block-name}__input{
            line-height: 1;
            font-size: 16px;
            min-height: 50px;
            vertical-align: bottom;
            padding-top: 16px;
            padding-bottom: 0;
            color: $black;
            border-color: $gray;
            border-radius: 10px;
        }

        textarea.field-text__input{
            height: 50px;
            padding-top: 24px;
            padding-bottom: 10px;
        }

        #{$block-name}__input:focus ~ #{$block-name}__name,
        #{$block-name}__input:not(:placeholder-shown) ~ #{$block-name}__name {
            transform: translateY(-10px) scale(0.75);
        }

        #{$block-name}__icon{
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }

    &--dark{
        #{$block-name}__input{
            color: $black;
            border-bottom: 1px solid $black;

            &::placeholder {
                color: $black;
            }
        }
    }
}
