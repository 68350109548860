.blog{
    $block-name: &; // #{$block-name}__element

    &__wrapper{
        display: grid;
        grid-gap: 60px;

        @include min($screen-xl){
            grid-template-columns: 1fr 400px;
            grid-gap: 36px;

        }
    }

    &__list{
        display: grid;
        grid-gap: 20px;
        margin-bottom: 52px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px 16px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            grid-gap: 56px 24px;
        }
    }

    &--article{
        margin-bottom: 60px;

        @include min($screen-xl){
            margin-bottom: 100px;
        }

        #{$block-name}__wrapper{
            @include min($screen-xl){
                grid-gap: 100px;
            }
        }
    }


}
