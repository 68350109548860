.hero-vacancies {
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    padding-top: 150px;
    padding-bottom: 32px;

    @include min($screen-md) {
        padding-top: 145px;
        padding-bottom: 40px;
    }

    @include min($screen-xl) {
        padding-top: 200px;
        padding-bottom: 100px;
    }

    &:before {
        content: '';
        position: absolute;
        right: -220px;
        top: -160px;
        z-index: 0;
        background-image: url(../img/pic-2.svg);
        background-repeat: no-repeat;
        background-origin: border-box;
        width: 480px;
        height: 510px;
        transform: rotate(-13deg);
        background-size: contain;

        @include min($screen-md) {
            width: 950px;
            height: 1010px;
            right: -440px;
            top: -230px;
        }

        @include min($screen-xl) {
            width: 2164px;
            height: 2304px;
            right: -980px;
            top: -950px;
        }
    }

    &__container {
        position: relative;
        z-index: 1;
    }

    &__name {
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        color: $blue-light;
        margin-bottom: 24px;

        @include min($screen-md) {
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 40px;
        }

        @include min($screen-xl) {
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
        }
    }

    &__title {
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 12px;

        @include min($screen-md) {
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 16px;
        }

        @include min($screen-xl) {
            font-size: 54px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 24px;

        }
    }

    &__text {
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md) {
            font-size: 16px;
            letter-spacing: -0.16px;
            max-width: 420px;
        }

        @include min($screen-xl) {
            font-size: 19px;
            letter-spacing: -0.19px;
            max-width: 580px;
        }
    }


    &--single{
        background-color: $gray-lighter;
        padding-bottom: 20px;

        @include min($screen-md){
            padding-bottom: 24px;
        }

        @include min($screen-xl){
            padding-bottom: 40px;
        }

        #{$block-name}__text{
            @include min($screen-md){
                max-width: 390px;
            }

            @include min($screen-xl){
                max-width: 465px;
            }
        }
    }

}
