.vacancy-another{
    $block-name: &; // #{$block-name}__element

    background-color: $gray-lighter;
    padding: 24px;

    @include min($screen-xl){
        padding: 40px 32px;
    }

    &__title{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 20px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 26px;
        }
    }

    &__list{
        margin-bottom: 20px;

        @include min($screen-xl){
            margin-bottom: 26px;
        }
    }

    &__link{
        display: inline-block;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        padding: 4px 0;
        color: $black;

        @include min($screen-xl){
            font-size: 19px;
            line-height: 130%;
            letter-spacing: -0.19px;
            padding: 8px 0;
        }

        &:hover, &:focus-visible{
            text-decoration: underline;
        }
    }

    &__all{
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.36px;
        text-transform: lowercase;
        color: $blue;

        @include min($screen-md){
            font-size: 16px;
            line-height: 118%;
            letter-spacing: -0.32px;
        }
    }
}
