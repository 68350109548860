.education-list{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-md){
            grid-template-columns: repeat(2, 1fr);
        }

        @include min($screen-xl){
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item{
        padding: 24px 16px;
        background-color: $gray-lighter;
        border: 1px solid $gray-light;

        @include min($screen-md){
            padding: 32px 24px;
        }

        @include min($screen-xl){
            padding: 40px 24px;
            border: none;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 24px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }
    }
}
