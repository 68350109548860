.btn{
    $block-name: &; // #{$block-name}__element

    position: relative;
    display: inline-block;
    overflow: visible;
    font: inherit;
    text-align: inherit;
    text-decoration: none;
    background: transparent;
    border: none;
    cursor: pointer;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;
    box-sizing: border-box;
    font-weight: 400;
    padding: 0;

    &__up{
        position: relative;
        z-index: 1;
        width: 100%;
        text-align: center;
        padding: 16px 20px;
        font-size: 12px;
        text-transform: lowercase;
        letter-spacing: -0.33px;
        transition: $transition-time;


        @include min($screen-md){
            padding: 20px 32px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.36px;
        }

        @include min($screen-xl){
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.18;
            letter-spacing: -0.32px;
        }
    }

    &__down{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__line{
        opacity: 0;
        transition: opacity 0.05s ease-in-out;

        &--first{
            display: block;
            width: 12px;
            height: 1px;
            position: absolute;
            left: -9px;
            bottom: 4px;
            transform: rotate(45deg);
        }

        &--second {
            display: block;
            width: 12px;
            height: 1px;
            position: absolute;
            right: -1px;
            bottom: 5px;
            transform: rotate(45deg);
        }

        &--third{
            display: block;
            width: 12px;
            height: 1px;
            position: absolute;
            right: -1px;
            top: -4px;
            transform: rotate(45deg);
        }
    }

    &:hover, &:focus-visible{
        #{$block-name}__up{
            transform: translate(-8px, -8px);
        }

        #{$block-name}__line{
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
        }
    }

    &--red{
        #{$block-name}__up{
            //display: inline-block;
            background-color: $red;
            color: $black;
        }

        #{$block-name}__down{
            border: 1px solid $red;
        }

        #{$block-name}__line{
            &--first{
                background-color: $red;
            }

            &--second{
                background-color: $red;
            }

            &--third{
                background-color: $red;
            }
        }
    }

    &--blue{
        #{$block-name}__up{
            display: inline-block;
            background-color: $blue;
            color: $white;
        }

        #{$block-name}__down{
            border: 1px solid $blue;
        }

        #{$block-name}__line{
            &--first{
                background-color: $blue;
            }

            &--second{
                background-color: $blue;
            }

            &--third{
                background-color: $blue;
            }
        }
    }


    &--white{
        #{$block-name}__up{
            display: inline-block;
            color: $white;
            //background-color: $white;
            //color: $black;
        }

        #{$block-name}__down{
            border: 1px solid $white;
        }

        #{$block-name}__line{
            &--first{
                background-color: $white;
            }

            &--second{
                background-color: $white;
            }

            &--third{
                background-color: $white;
            }
        }

        &:hover, &:focus-visible{
            #{$block-name}__up{
                background-color: $white;
                color: $black;
            }
        }
    }

    &--copy{
        display: flex;
        align-items: center;
    }
}
