.blog-interested{
    $block-name: &; // #{$block-name}__element

    background-color: $gray-lighter;
    padding: 60px 0;

    @include min($screen-xl){
        padding: 100px 0;
    }

    &__title{
        font-size: 21px;
        font-weight: 500;
        line-height: 97%;
        letter-spacing: -0.42px;
        margin-bottom: 24px;

        @include min($screen-xl){
            font-size: 40px;
            line-height: 94%;
            letter-spacing: -1.6px;
            margin-bottom: 56px;
        }

        &--btn{
            display: flex;
            gap: 24px;

            span{
                @include min($screen-xl){
                    max-width: 860px;
                }
            }
        }
    }

    &__btn{
        flex-shrink: 0;
        margin-left: auto;
        align-self: flex-start;
        display: flex;
        justify-content: center;

        @include min($screen-md){
            display: inline-block;

        }

        &--desktop{
            display: none;

            @include min($screen-md){
                display: flex;
            }
        }

        &--mobile{
            margin-top: 24px;

            @include min($screen-md){
                display: none;
            }
        }
    }

    .swiper-slide{
        @include max($screen-md){
            width: 206px;
        }
    }

    .swiper-wrapper{
        @include min($screen-md){
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 16px;
        }
    }
}
