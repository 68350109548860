.field-file {

    $block-name: &; // #{$block-name}__element

    display: block;
    //margin-bottom: $typo-margin-vertical;

    &__name {
        @include field-name;
    }

    &__input-wrap {
        display: block;
        position: relative;
    }

    &__input {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:active ~ .field-file__name-text,
        &:focus ~ .field-file__name-text {
            @include field-focus;
        }
    }

    &__name-text {
        display: inline-block;
        vertical-align: middle;
        //display: inline-flex;
        align-items: center;
        text-align: center;
        position: relative;
        width: 100%;
        min-width: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        overflow: hidden;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.32px;
        line-height: 118%;
        color: $gray-lighter;
        text-transform: lowercase;
        border: 1px solid $gray-lighter;
        padding: 12px 32px;

        @include min($screen-md){
            padding: 12px 24px;
        }

        @include min($screen-xl){
            padding: 18px 22px;
        }

        @at-root input:disabled ~ & {
            background: $gray-lighter;
            cursor: not-allowed;
        }

        &:before {
            content: attr(data-button-text);
            display: inline-block;
            vertical-align: middle;
            background-image: svg-load('paperclip.svg', color=$gray-lighter);
            width: 20px;
            height: 20px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            margin-right: 8px;
            //background-color: $gray-lightest;
            //border-right: 1px solid $border-color;
            //margin-right: $field-padding-horizontal;
            //line-height: $line-height;
            //padding: $field-padding-vertical $field-padding-horizontal;
            //border-top-left-radius: $border-radius;
            //border-bottom-left-radius: $border-radius;
        }

        &:hover,
        &:focus {

            &:before {
                //background-color: darken($gray-lightest, 10%); // как у обычных кнопок
            }
        }
    }

    &__help-text {
        @include field-help-text;
    }


    &--error {

        #{$block-name}__name,
        #{$block-name}__name-text,
        #{$block-name}__input,
        #{$block-name}__help-text {
            color: $red;
        }

        #{$block-name}__name-text {
            border-color: $red;
            background-color: lighten($red, 35%);
        }
    }

    &--dark{
        #{$block-name}__name,
        #{$block-name}__name-text,
        #{$block-name}__input,
        #{$block-name}__help-text {
            color: $black;
        }

        #{$block-name}__name-text{
            &:before{
                background-image: svg-load('paperclip.svg', color=$black);
            }
        }

        #{$block-name}__name-text {
            border-color: $black;
            //background-color: lighten($black, 35%);
        }
    }
}
