.tariff{
    $block-name: &; // #{$block-name}__element

    background-color: $white ;
    padding: 56px 0;

    @include min($screen-md){
        padding: 64px 0;
    }

    @include min($screen-xl){
        padding: 100px 0;
    }

    &__title{
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 24px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 40px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            max-width: 390px;
        }

        @include min($screen-xl){
            font-size: 24px;
            letter-spacing: -0.24px;
            margin-bottom: 54px;
            max-width: 600px;
        }
    }

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-xl){
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &__item{
        background-color: $gray-lighter;
        padding: 24px 16px;

        @include min($screen-md){
            padding: 32px 24px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
        }

        @include min($screen-xl){
            display: flex;
            flex-direction: column;
            padding: 40px 32px;
        }
    }

    &__item-left{
        margin-bottom: 24px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            margin-bottom: 36px;
        }
    }

    &__item-right{

        @include min($screen-md){
            flex-grow: 1;
            display: grid;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 16px;

        @include min($screen-md){
            font-size: 26px;
            line-height: 96%;
            letter-spacing: -0.52px;
        }

        @include min($screen-xl){
            font-size: 30px;
            letter-spacing: -1.2px;
            margin-bottom: 24px;
        }
    }

    &__cost{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__time{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }
    }

    &__item-text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-bottom: 30px;
        }
    }

    &__btn{
        margin-top: auto;
    }



}
