.modal{
    $block-name: &; // #{$block-name}__element




    &--menu{
        overflow: hidden;
        background-color: $black;

        .container{
            position: relative;
            z-index: 0;
        }

        #{$block-name}__img{
            visibility: hidden;
            opacity: 0;
            //visibility: visible;
            //opacity: 1;
            position: absolute;
            z-index: 0;
            transition: opacity $transition-time;

            img{
                width: 206px;
                height: auto;
                object-fit: contain;

                @include min($screen-xl){
                    width: 318px;
                }

            }

            &.show{
                visibility: visible;
                opacity: 1;
            }
        }

        #{$block-name}__top{
            position: relative;
            z-index: 1;
            padding-top: 24px;

            .header__container{
                min-height: 37.19px;
                display: flex;
                align-items: center;

                @include min($screen-xl){
                    min-height: 58.88px;
                }
            }

            .header__wrapper{
                flex-grow: 1;
            }

            .header__button{
                display: flex;
                align-items: center;
            }
        }

        #{$block-name}__main{
            position: relative;
            z-index: 1;
            padding-top: 54px;
            padding-bottom: 100px;

            @include min($screen-xl){
                padding-top: 77px;
            }
        }
    }

    &--web{
        #{$block-name}__img{
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--mobile{
        #{$block-name}__img{
            top: -70px;
            right: 50px;
        }
    }

    &--bots{
        #{$block-name}__img{
            bottom: -120px;
            left: 100px;

            img{
                width: 300px;

                @include min($screen-xl){
                    width: 400px;
                }
            }
        }
    }

    &--seo{
        #{$block-name}__img{
            bottom: -150px;
            right: -150px;

            img{
                width: 400px;

                @include min($screen-xl){
                    width: 500px;
                }
            }
        }
    }

    &--solutions{
        #{$block-name}__img{
            bottom: -280px;
            right: -280px;

            @include min($screen-xl){
                bottom: -250px;
                right: -450px;
            }

            img{
                width: 768px;

                @include min($screen-xl){
                    width: 916px;
                }
            }
        }
    }
}

.hystmodal{
    $block-name: &; // #{$block-name}__element`

    &__close{
        background-image: svg-load('cross-modal.svg', color=$white);
        width: 26px;
        height: 26px;
    }

    &--menu{
        align-content: start;
        width: calc(100% - var(--get-scrollbar-width));
        //height: auto;

        &:before{
            display: none;
        }

        #{$block-name}__close{
            position: relative;
            top: 0;
            right: 0;
        }

        &.hystmodal--animated{
            #{$block-name}__window{
                transition: opacity var(--hystmodal-speed) ease 0s;
            }
        }

        #{$block-name}__window{
            width: 100%;
            margin-top: 0;
        }
    }

    &--contact{
        grid-template-rows: 0 100% 0;

        @include min($screen-md){
            grid-template-rows: 0 min-content 0;
        }

        #{$block-name}__window{
            max-width: 1289px;
            width: 100%;
            height: 100%;

            @include min($screen-md){
                height: auto;
            }
        }
    }
}
