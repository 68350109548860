.social{
    $block-name: &; // #{$block-name}__element

    background-color: $gray-lighter;

    padding: 16px;

    max-width: 385px;

    @include min($screen-md){
        padding: 24px 16px;
    }

    @include min($screen-xl){
        padding: 32px 24px;
        max-width: 100%;
    }

    &__text{
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.3px;
        margin-bottom: 16px;

        @include min($screen-md){
            font-size: 18px;
            letter-spacing: -0.36px;
        }

        @include min($screen-xl){
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
            margin-bottom: 24px;
        }
    }

    &__list{
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__icon{
        width: 36px;
        height: 36px;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &--article{
        background-color: transparent;
        padding: 0;
        margin: 12px 0;

        @include min($screen-md){
            margin: 16px 0;
        }

        @include min($screen-xl){
            margin: 32px 0;
        }

        #{$block-name}__icon{
            width: 40px;
            height: 40px;
        }
    }
}
