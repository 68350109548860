.portfolio-detail{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-gap: 24px;
        grid-template-columns: repeat(3, 1fr);
        margin-bottom: 40px;

        @include min($screen-md){
            margin-bottom: 64px;
        }
    }

    &__item-title{
        color: $black;
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.34px;
        margin-bottom: 20px;

        @include min($screen-xl){
            font-size: 30px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -1.2px;
        }
    }
    &__item-text{
        font-size: 16px;
        color: $black;

        @include min($screen-xl){
            font-size: 19px;
        }
    }

    &__images{
        margin-bottom: 40px;

        @include min($screen-md){
            margin-bottom: 64px;
        }

        #{$block-name}__img + #{$block-name}__img{
            margin-top: 16px;
        }
    }

    &__img{
        img{
            width: 100%;
            max-width: none;
        }
    }

    &__content{
        margin-bottom: 40px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 24px;
            margin-bottom: 64px;
        }

        @include min($screen-xl){
            grid-template-columns: 610px 1fr;
        }
    }

    &__content-title{
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.34px;
        margin-bottom: 24px;

        br{
            display: none;

            @include min($screen-md){
                display: inline;
            }
        }

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 30px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -1.2px;
        }

        &--big{
            font-size: 21px;
            font-weight: 500;
            line-height: 0.97;
            letter-spacing: -0.42px;
            margin-bottom: 40px;

            @include min($screen-md){
                font-size: 32px;
                font-weight: 500;
                line-height: 0.96;
                letter-spacing: -0.64px;
                margin-bottom: 48px;
            }

            @include min($screen-xl){
                font-size: 50px;
                font-weight: 500;
                line-height: 0.94;
                letter-spacing: -2.5px;
                margin-bottom: 56px;
            }
        }
    }

    &__content-text{
        font-size: 16px;

        @include min($screen-xl){
            font-size: 19px;
        }

        p + p{
            margin-top: 15px;
        }
    }

    &__descr{
        padding: 20px;
        background-color: $white;

        @include min($screen-md){
            display: grid;
            grid-gap: 24px;
            grid-template-columns: 1.5fr 0.5fr;
            padding: 30px;
        }

        @include min($screen-xl){
            padding: 40px;
            grid-template-columns: 1fr 330px;
            grid-gap: 60px;
        }

        &--reviews{
            background-color: transparent;
            padding: 0;

            #{$block-name}__caption{
                justify-self: center;
            }
        }
    }

    &__descr-text{
        margin-bottom: 24px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        p{
            margin-bottom: 15px;

            &:last-child{
                margin-bottom: 0;
            }
        }

        ol{
            margin-bottom: 15px;
            display: block;
            list-style-type: decimal;
            padding-left: 20px;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }


    &__caption{
        //display: flex;
        //align-items: center;
    }

    &__caption-img{
        flex-shrink: 0;
        width: 50px;
        height: 50px;
        margin-bottom: 24px;

        @include min($screen-md){
            width: 80px;
            height: 80px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__caption-icon{
        margin-bottom: 24px;
    }

    &__caption-name{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        margin-bottom: 4px;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.16px;
        }
    }

    &__caption-post{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        color: $black-light;
        opacity: 0.57;

        @include min($screen-md){
            font-size: 9px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -0.27px;
        }

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 500;
            line-height: 0.87;
            letter-spacing: -0.48px;
        }
    }
}
