.menu-industry{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px 20px;
        margin-left: 135px;

        @include min($screen-xl){
            margin-left: 210px;
            grid-gap: 32px 40px;
        }
    }

    &__link{
        display: flex;
        align-items: center;
        color: $gray;

        &:hover, &:focus-visible{
            color: $gray-lighter;

            #{$block-name}__hidden{
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    &__icon{
        flex-shrink: 0;
        color: $blue-light;
        width: 40px;
        height: 40px;
        margin-right: 12px;

        @include min($screen-xl){
            width: 54px;
            height: 54px;
            margin-right: 24px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__text{
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.36px;

        @include min($screen-xl){
            font-size: 30px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__hidden{
        transform: translateX(-5px);
        margin-left: 10px;
        margin-top: 3px;
        opacity: 0;
        transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
    }
}
