.main-reviews{
    $block-name: &; // #{$block-name}__element
    overflow: hidden;

    margin-bottom: 100px;

    @include min($screen-md){
        margin-bottom: 64px;
    }

    @include min($screen-xl){
        margin-bottom: 140px;
    }

    &__title{
        @include min($screen-md){
            padding-right: 70px;
        }

        br{
            display: none;

            @include min($screen-md){
                display: inline;
            }
        }
    }

    &__wrapper{
        position: relative;
        padding-bottom: 35px;

        @include min($screen-md){
            width: 80%;
            padding-bottom: 0;
        }

        @include min($screen-xl){
            width: 100%;
            padding-bottom: 28px;

        }
    }

    &__slider{
        @include min($screen-md){
            overflow: visible;
        }

        @include min($screen-xl){
            overflow: hidden;
        }
    }

    &__navigation{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;

        display: flex;
        align-items: center;
        gap: 10px;

        @include min($screen-md){
            top: -80px;
            bottom: auto;
            transform: none;
            left: auto;
            //right: 0;
            right: calc(-20% - 46px);
        }

        @include min($screen-xl){
            right: 0;
        }
    }

    &__pagination{
        display: none;

        @include min($screen-xl){
            display: block;
        }
    }

    .swiper-slide {
        @include min($screen-md){
            //height: 100%;
            height: auto;
        }

    }

    &__slide{
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: $white;
        padding: 16px 16px 24px;
        box-sizing: border-box;

        @include min($screen-xl){
            padding: 24px;
        }
    }

    &__img{
        margin-bottom: 16px;

        @include min($screen-xl){
            margin-bottom: 24px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 16px;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.16px;
            margin-bottom: 20px;
        }

        p + p{
            margin-top: 13px;
        }
    }

    &__link{
        display: inline-block;
        margin-top: 16px;
        color: $gray;

        @include min($screen-xl){
            margin-top: 20px;
        }
    }

    &__name{
        margin-top: auto;
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        margin-bottom: 4px;

        @include min($screen-xl){
            font-size: 17px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.68px;
        }
    }

    &__post{
        font-size: 9px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.27px;
        color: $gray;

        @include min($screen-xl){
            font-size: 13px;
            font-weight: 500;
            line-height: 118%;
            letter-spacing: -0.26px;
        }
    }

    &--bg{
        #{$block-name}__slide{
            background-color: $gray-lighter;
        }
    }

    &--gray-light{
        background-color: $gray-lighter;
        margin-bottom: 0;
        padding-bottom: 60px;

        @include min($screen-md){
            padding-bottom: 80px;
        }

        @include min($screen-xl){
            padding-bottom: 100px;
        }

    }
}
