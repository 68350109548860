.industries-list{
    $block-name: &; // #{$block-name}__element

    background-color: $gray-lighter;

    padding-top: 60px;
    padding-bottom: 80px;

    @include min($screen-md){
        padding-top: 80px;
    }

    @include min($screen-xl){
        padding-top: 100px;
        padding-bottom: 140px;
    }

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-md){
            //grid-template-columns: repeat(3, 1fr);
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__link{
        display: inline-block;
        color: $white;
        width: 100%;

        img { max-height: 250px; }
        p { margin: 15px 0px 0px 0px; }

        .btn__up{
            background-color: $white;
            color: $black;
            text-align: left;
            text-transform: initial;
            padding: 24px 16px;

            @include min($screen-md){
                padding: 24px;
            }

            @include min($screen-xl){
                padding: 32px;
            }
        }

        &:hover, &:focus-visible{
            .btn__up{
                color: $white;
                background-color: $blue;
            }

            #{$block-name}__icon{

                &--medicine{
                    background-image: svg-load('medicine.svg', color=$white);
                }

                &--education{
                    background-image: svg-load('education.svg', color=$white);
                }

                &--busines{
                    background-image: svg-load('busines.svg', color=$white);
                }

                &--gos{
                    background-image: svg-load('gos.svg', color=$white);
                }

                &--prod{
                    background-image: svg-load('prod.svg', color=$white);
                }

                &--vus{
                    background-image: svg-load('vus.svg', color=$white);
                }

            }
        }
    }

    &__icon{
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50% 50%;
        width: 40px;
        height: 40px;
        margin-bottom: 16px;

        @include min($screen-md){
            width: 48px;
            height: 48px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            width: 65px;
            height: 65px;
            margin-bottom: 36px;
        }

        &--medicine{
            background-image: svg-load('medicine.svg', color=$blue-light);
        }

        &--education{
            background-image: svg-load('education.svg', color=$blue-light);
        }

        &--busines{
            background-image: svg-load('busines.svg', color=$blue-light);
        }

        &--gos{
            background-image: svg-load('gos.svg', color=$blue-light);
        }

        &--prod{
            background-image: svg-load('prod.svg', color=$blue-light);
        }

        &--vus{
            background-image: svg-load('vus.svg', color=$blue-light);
        }
    }

    &__text{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }
}
