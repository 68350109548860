.about-team{
    $block-name: &; // #{$block-name}__element

    &__top{
        display: flex;
        align-items: flex-end;
        gap: 20px;
        margin-bottom: 32px;

        @include min($screen-md){
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 56px;

        }
    }

    &__title{
        margin-bottom: 0;

        @include min($screen-md){
            max-width: 495px;
        }

        @include min($screen-xl){
            max-width: 600px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-top: 12px;

        @include min($screen-md){
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            margin-top: 24px;

        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.24px;

        }
    }

    &__navigation{
        margin-left: auto;
        display: flex;
        align-items: center;
        gap: 12px;
    }

    &__slider{
        padding-top: 8px;
        padding-left: 8px;
        margin-left: -8px;
    }

    &__slide{
        position: relative;
        box-sizing: border-box;

        &:hover, &:focus-visible{

            #{$block-name}__img{
                &:after{
                    @include min($screen-xl){
                        border-color: $blue;
                    }
                }
            }

            #{$block-name}__hover{
                visibility: visible;
                opacity: 1;

                @include min($screen-xl){
                    transform: translate(-8px, -8px);
                }

            }

            #{$block-name}__line{
                opacity: 1;
                transition: opacity 0.3s ease-in-out;
            }
        }
    }

    &__img{
        position: relative;
        margin-bottom: 16px;

        @include min($screen-xl){
            margin-bottom: 24px;
        }

        > img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:before{
            content: '';
            display: block;
            padding-top: 100%;
        }

        &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 2px solid transparent;
            transition: border-color $transition-time;

        }
    }


    // Hover
    &__hover{
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $blue;
        visibility: hidden;
        opacity: 0;
        transition: $transition-time;
        padding: 16px;

        @include min($screen-md){
            padding-top: 26px;
            padding-bottom: 26px;
        }

        @include min($screen-xl){
            padding: 20px;
        }
    }

    &__hover-text{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        color: $gray-lighter;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.24px;
        }
    }

    &__hover-bottom{
        flex-shrink: 0;
        margin-top: auto;
        margin-left: auto;
        display: flex;
        gap: 12px;
        align-items: center;

        img{
            width: 48px;
            height: 48px;

            @include min($screen-xl){
                width: 60px;
                height: 60px;
            }
        }
    }

    &__line{
        opacity: 0;
        transition: opacity 0.1s ease-in-out;

        &--first{
            display: none;

            @include min($screen-xl){
                display: block;
                width: 12px;
                height: 2px;
                background-color: $blue;
                position: absolute;
                left: -1px;
                bottom: -4px;
                transform: rotate(45deg);
            }
        }

        &--second{
            display: none;

            @include min($screen-xl){
                display: block;
                width: 13px;
                height: 2px;
                background-color: $blue;
                position: absolute;
                right: -9px;
                bottom: -4px;
                transform: rotate(45deg);
            }
        }

        &--third{
            display: none;

            @include min($screen-xl){
                display: block;
                width: 13px;
                height: 2px;
                background-color: $blue;
                position: absolute;
                right: -9px;
                top: 3px;
                transform: rotate(45deg);
            }
        }
    }
    // end hover

    &__name{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
        }
    }

    &__post{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        color: $gray;

        @include min($screen-xl){
            font-size: 16px;
            line-height: 87.018%;
            letter-spacing: -0.48px;
        }
    }
}
