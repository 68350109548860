.vacancy{
    $block-name: &; // #{$block-name}__element

    margin-top: 40px;

    @include min($screen-md){
        margin-top: 60px;
    }

    @include min($screen-xl){
        margin-top: 100px;
    }

    &__wrapper{
        display: grid;
        grid-gap: 32px;

        @include min($screen-md){
            grid-template-columns: 1fr 230px;
            grid-gap: 24px;

        }

        @include min($screen-xl){
            grid-template-columns: 1fr 400px;
            grid-gap: 200px;

        }
    }

    &__another-wrap{
        align-self: start;
        display: grid;
        align-items: start;
        grid-gap: 16px;

        @include min($screen-md){
            grid-gap: 24px;
        }
    }
}
