.filter{
    $block-name: &; // #{$block-name}__element

    display: none;

    @include min($screen-md){
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 48px;
    }

    @include min($screen-xl){
        display: none;
        margin-bottom: 56px;
    }

    &__link{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        padding: 5px;
        margin-right: 10px;
        color: $black;
        transition: background $transition-time;

        @include min($screen-xl){
            font-size: 19px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.19px;
            padding: 8px;
            margin-right: 20px;
        }

        &:hover, &:focus-visible{
            background-color: $red;
        }

        &--active{
            background-color: $red;
        }
    }

    &--services{
        @include min($screen-xl){
            display: flex;
        }
    }

    &--portfolio{
        @include min($screen-xl){
            display: flex;
        }

        #{$block-name}__link{
            color: $white;
        }
    }
}
