.menu-services{
    $block-name: &; // #{$block-name}__element

    display: grid;
    grid-gap: 24px 16px;

    @include min($screen-xl){
        grid-gap: 58px 50px;
    }

    &__link{
        display: inline-block;
        color: $gray;

        &:hover, &:focus-visible{
            color: $gray-lighter;

            #{$block-name}__hidden{
                transform: translateX(0);
                opacity: 1;
            }
        }
    }

    &__title{
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.36px;
        margin-bottom: 12px;

        @include min($screen-xl){
            font-size: 30px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 18px;
        }
    }

    &__hidden{
        transform: translateX(-5px);
        margin-left: 10px;
        margin-top: 4px;
        opacity: 0;
        transition: transform $transition-time ease-in-out, opacity $transition-time ease-in-out;
    }

    &__sublist{
        span{
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 8px;
            font-size: 11px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.33px;

            @include min($screen-xl){
                margin-right: 11px;
                margin-bottom: 11px;
                font-size: 16px;
                font-weight: 500;
                line-height: 87.018%;
                letter-spacing: -0.48px;
            }

            a {
                color: #838383;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    &--column{
        grid-template-columns: 1fr 1fr;
    }

    &--width{
        @include min($screen-xl){
            grid-gap: 40px 50px;
        }

        #{$block-name}__sublist{
            span{
                max-width: 265px;

                @include min($screen-xl){
                    max-width: 445px;
                    line-height: 116%;
                }
            }
        }
    }

    &--title{
        grid-gap: 16px;

        @include min($screen-xl){
            grid-gap: 27px;
        }

        #{$block-name}__title{
            margin-bottom: 0;

            @include min($screen-xl){
                font-size: 50px;
            }
        }

        #{$block-name}__hidden{
            margin-top: 0;
        }
    }
}
