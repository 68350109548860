.header-menu{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: flex;
        align-items: center;
    }

    &__item{
        &--active{
            #{$block-name}__link{
                position: relative;

                &:after{
                    content: '';
                    height: 1px;
                    width: calc(100% - 24px);
                    background-color: $gray-lighter;
                    position: absolute;
                    bottom: 8px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    &__link{
        position: relative;
        color: $white;
        display: flex;
        padding: 12px;
        font-size: 11px;

        @include min($screen-xl){
            font-size: 16px;
        }

        &:after{
            content: '';
            display: block;
            position: absolute;
            left: 12px;
            bottom: 8px;
            width: 0;
            height: 1px;
            background-color: $white;
            transition: width $transition-time ease-out;
        }

        &:hover, &:focus-visible{
            &:after{
                width: calc(100% - 24px);
            }
        }
    }

    &__content{
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        z-index: 1;
        opacity: 0;
        transition: opacity 0.5s ease;

        &.show{
            display: block;
            opacity: 1;
        }
    }
}
