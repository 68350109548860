.about-list{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 32px 100px
        }

        @include min($screen-xl){
            grid-gap: 68px 100px;
            grid-template-columns: 430px 430px;
            padding-left: 207px;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 12px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 20px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }
    }
}
