.interested-slide{
    $block-name: &; // #{$block-name}__element

    color: $black;

    &__img{
        position: relative;
        height: 112px;
        width: 100%;
        margin-bottom: 16px;

        @include min($screen-md){
            height: auto;
        }

        &:before{
            display: none;

            @include min($screen-md){
                content: '';
                display: block;
                padding-top: 61%;
            }

            @include min($screen-xl){
                padding-top: 65%;
            }
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include min($screen-md){
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.26px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 14px;
            letter-spacing: -0.28px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
            margin-bottom: 24px;
        }
    }

    &__date{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        color: $blue;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 118%;
            letter-spacing: -0.32px;
        }
    }
}
