.hero-canvas{
    $block-name: &; // #{$block-name}__element

    position: absolute;
    top: -120px;
    right: -150px;
    width: 500px;
    height: 500px;

    @include min($screen-md){
        //top: auto;
        right: -150px;
        top: -180px;
    }

    @include min($screen-xl){
        width: 950px;
        height: 950px;
        top: -400px;
        bottom: auto;
        right: -300px;
    }
}
