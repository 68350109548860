.hero-about{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    background-color: $gray-lighter;
    padding-top: 125px;
    padding-bottom: 56px;

    @include min($screen-md){
        padding-top: 160px;
        padding-bottom: 48px;
    }

    @include min($screen-xl){
        padding-top: 245px;
        padding-bottom: 40px;
    }

    &:before{
        content: '';
        position: absolute;
        right: -1670px;
        top: -500px;
        z-index: 0;
        background-image: url(../img/pic-3.svg);
        background-repeat: no-repeat;
        background-origin: border-box;
        width: 1970px;
        height: 1415px;
        background-size: contain;

        @include min($screen-md){
            width: 3415px;
            height: 2455px;
            right: -2950px;
            top: -900px;
        }

        @include min($screen-xl){
            right: -2780px;
            top: -850px;
        }
    }

    &__container{
        position: relative;
        z-index: 1;

        @include min($screen-md){
            padding-left: 160px;
        }

        @include min($screen-xl){
            padding-left: 230px;
        }
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 62px;
            font-weight: 400;
            line-height: 90%;
            letter-spacing: -3.1px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 84px;
            line-height: 94%;
            letter-spacing: -4.2px;
            margin-bottom: 40px;
        }
    }

    &__text{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            max-width: 450px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            max-width: 610px;
        }

        a{
            color: $blue;

            &:hover, &:focus-visible{
                color: $blue-light;
            }
        }
    }
}
