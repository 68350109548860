.main-blog{
    $block-name: &; // #{$block-name}__element

    margin-bottom: 55px;

    @include min($screen-md){
        margin-bottom: 43px;
    }

    @include min($screen-xl){
        margin-bottom: 140px;
    }

    &__title{
        display: flex;
        gap: 24px;

        span{
            @include min($screen-xl){
                max-width: 860px;
            }
        }
    }

    &__btn{
        flex-shrink: 0;
        margin-left: auto;
        align-self: flex-start;
        display: flex;
        justify-content: center;

        @include min($screen-md){
            display: inline-block;

        }

        &--desktop{
            display: none;

            @include min($screen-md){
                display: flex;
            }
        }

        &--mobile{
            @include min($screen-md){
                display: none;
            }
        }
    }

    &__list{
        display: grid;
        grid-row-gap: 36px;
        margin-bottom: 36px;

        @include min($screen-md){
            margin-bottom: 32px;
        }

        @include min($screen-xl){
            margin-bottom: 48px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        &--top{
            @include min($screen-md){
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 16px;
            }

            @include min($screen-xl){
                grid-template-columns: 60% 40%;
            }

            #{$block-name}__img{
                &:before{
                    @include min($screen-md){
                        padding-top: 48%;
                    }

                    @include min($screen-xl){
                        padding-top: 260px;
                    }
                }
            }
        }

        &--bottom{
            @include min($screen-md){
                grid-template-columns: 1fr 1fr 1fr;
                grid-column-gap: 16px;
            }

            #{$block-name}__img{
                &:before{
                    @include min($screen-md){
                        padding-top: 61%;
                    }

                    @include min($screen-xl){
                        padding-top: 260px;
                    }
                }
            }
        }

    }

    &__img{
        position: relative;
        margin-bottom: 12px;

        @include min($screen-md){
            margin-bottom: 20px;
        }

        @include min($screen-xl){
            margin-bottom: 24px;
        }

        &:before{
            content: '';
            display: block;
            padding-top: 51%;

            @include min($screen-xl){
                padding-top: 260px;
            }
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.34px;
        margin-bottom: 12px;
        color: $black;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 0.96;
            letter-spacing: -1.2px;
            margin-bottom: 24px;
        }
    }

    &__date{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        color: $blue;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 1.18;
            letter-spacing: -0.32px;
        }
    }
}
