.about-icons{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-template-columns: 1fr 1fr;

        @include min($screen-md){
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__item{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;

        border-right: 1px solid $gray-light;
        border-bottom: 1px solid $gray-light;
        height: 110px;

        @include max($screen-md){
            &:nth-child(2n){
                border-right: none;
            }

            &:nth-last-child(-n+2){
                border-bottom: none;
            }
        }

        @include min($screen-md){
            height: 160px;
            padding: 30px;

            &:nth-child(4n){
                border-right: none;
            }

            &:nth-last-child(-n+4){
                border-bottom: none;
            }
        }

        @include min($screen-xl){
            height: 190px;
        }


    }
}
