.main-clients{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-row-gap: 36px;
        margin-bottom: 40px;

        @include min($screen-md){
            grid-row-gap: 32px;
            margin-bottom: 48px;
        }

        @include min($screen-xl){
            grid-gap: 16px;
            grid-template-columns: 1fr 1fr;
            margin-bottom: 56px;
        }
    }

    &__link{
        display: inline-block;
        width: 100%;
        position: relative;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
        }

        @include min($screen-xl){
            display: block;
            height: 400px;
            padding: 30px;
        }

        &:hover, &:focus-visible{
            #{$block-name}__img{
                &:after{
                    @include min($screen-xl){
                        border: 2px solid $red;
                    }
                }

            }

        }
    }

    &__img{
        position: relative;
        //transition: $transition-time;
        margin-bottom: 12px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            position: absolute;
            left: 0;
            top: 0;
            //border: 2px solid transparent;
            height: 100%;
            width: 100%;
            padding: 30px;
        }

        &:before{
            content: '';
            padding-top: 61%;
            display: block;

            @include min($screen-md){
                padding-top: 51%;
            }

            @include min($screen-xl){
                display: none;
            }
        }

        &:after{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            border: 1px solid transparent;
            transition: border-color $transition-time;

        }

        picture, img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__name{
        position: absolute;
        top: 0;
        left: 0;
        padding: 6px;
        background-color: $red;
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;

        @include min($screen-md){
            display: none;
        }
    }

    &__descr{
        display: flex;
        flex-direction: column;

        @include min($screen-xl){
            position: relative;
            z-index: 1;
        }
    }

    &__name-tablet{
        display: none;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.13px;
        margin-bottom: 12px;

        @include min($screen-md){
            display: block;
        }

        @include min($screen-xl){
            display: none;
        }
    }

    &__item-title{
        font-size: 17px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.34px;
        margin-bottom: 12px;

        @include min($screen-xl){
            color: $gray-lighter;
            font-size: 30px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -1.2px;
        }

        @include max($screen-xl){
            color: #180f0f;
        }

        &--black{
            @include min($screen-xl){
                color: $black;
            }
        }

        br{
            @include max($screen-xl){
                display: none;
            }
        }
    }

    &__category{
        position: relative;
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        padding-left: 14px;

        @include min($screen-md){
            margin-top: auto;
            color: $gray;
        }

        @include min($screen-xl){
            display: none;
        }

        &:before{
            content: '';
            position: absolute;
            top: 3px;
            left: 0;
            width: 6px;
            height: 6px;
            background-color: $red;
        }
    }

    &__bottom{
        @include min($screen-md){
            display: flex;
            justify-content: center;
        }
    }

    &__btn{
        display: flex;
        justify-content: center;

        @include min($screen-md){
            display: inline-block;

        }

        .btn__up{
            padding: 16px 20px;
            text-transform: lowercase;

            @include min($screen-md){
                text-transform: initial;
                padding: 20px 32px;
            }

            @include min($screen-xl){
                text-transform: lowercase;
            }
        }
    }

    /* Hover block */
    &__hover {
        color: #180f0f;
    }
    &__hover-wrap{
        padding: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include min($screen-xl){

            display: grid;
            grid-template-columns: auto 1fr;
            grid-column-gap: 22px;

        }
    }

    &__hover-big{
        font-size: 34px;
        font-weight: 400;
        line-height: 0.94;
        color: $black;
        letter-spacing: -4.2px;
        @include min($screen-xl){
            font-size: 84px;
        }
    }

    &__hover-text{
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @include min($screen-xl){
            height: 100%;
        }
    }

    &__hover-top{
        margin-top: 10px;
        color: $black;
        font-size: 16px;
        font-weight: 500;
        line-height: 0.96;
        letter-spacing: -1.2px;
        @include min($screen-xl){
            font-size: 30px;
        }

    }

    &__hover-bottom{
        margin-top: auto;
        display: flex;
        gap: 30px;
    }

    &__hover-link{
        font-size: 16px;
        color: $black;
        font-weight: 500;
        line-height: 0.87;
        letter-spacing: -0.48px;
    }
    /* end hover block */


    //&--services{
    //    #{$block-name}__filter{
    //        @include min($screen-xl){
    //            display: flex;
    //        }
    //    }
    //}

    &--site{
        padding-top: 56px;

        @include min($screen-md){
            padding-top: 64px;
        }

        @include min($screen-xl){
            padding-top: 100px;
        }
    }

}
