.vacancy-cost{
    $block-name: &; // #{$block-name}__element

    background-color: $gray-lighter;
    padding: 24px;

    @include min($screen-xl){
        padding: 40px 32px;
    }

    &__tag{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        margin-bottom: 16px;
        color: $gray;

        @include min($screen-xl){
            font-size: 16px;
            line-height: 87.018%;
            letter-spacing: -0.48px;
        }
    }

    &__cost{
        margin-bottom: 24px;

        @include min($screen-xl){
            margin-bottom: 40px;
        }
    }

    &__sum{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__sum-bottom{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        color: $gray;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 118%;
            letter-spacing: -0.32px;
        }
    }

    &__descr{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-xl){
            font-size: 19px;
            line-height: 130%;
            letter-spacing: -0.19px;
        }

        p + p{
            margin-top: 8px;
        }
    }
}
