.tabs {

    $block-name: &; // #{$block-name}__element

    //margin-top: $typo-margin-vertical;
    //margin-bottom: $typo-margin-vertical;

    &__links {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin: 0;
        padding: 0;
        list-style: none;

        &:after {
            content: '';
            display: none;
            flex-grow: 1;
            height: 1px;
            //background-color: $border-color;

            @media (min-width: $screen-md) {
                display: block;
            }
        }

        .no-js & {
            display: none;
        }
    }

    &__link-wrap {
        position: relative;
        margin: 0 0.3em 0.3em 0;
        //border: 1px solid $border-color;
        //color: $text-color-muted;

        @media (min-width: $screen-md) {
            margin: 0;

            &:not(:last-child) {
                border-right: none;
            }
        }

        &--active {
            //color: $text-color;

            @media (min-width: $screen-md) {
                //border-bottom: 1px solid transparent;
            }

            //#{$block-name}__link {
            //    background-color: #fff;
            //}
        }
    }

    &__link {
        display: block;
        padding: $field-padding-vertical $field-padding-horizontal;
        color: currentColor;
        text-decoration: none;
        //background-color: $gray-lightest;

        &:hover,
        &:focus {
            color: currentColor;
        }
    }

    &__content-wrapper {
        display: block;
        //border: 1px solid $border-color;
        padding: $field-padding-vertical $field-padding-horizontal;

        @media (min-width: $screen-md) {
            border-top: none;
        }
    }

    &__content-item {

        .js & {
            display: none;
        }

        & > *:last-child { // stylelint-disable-line  selector-combinator-blacklist
            margin-bottom: 0;
        }

        &--active {

            .js & {
                display: block;
            }
        }
    }

    &--main-tabs{
        #{$block-name}__links{
            display: none;

            @include min($screen-md){
                display: flex;
            }
        }

        #{$block-name}__link-wrap{
            &:first-child{
                #{$block-name}__link{
                    padding-left: 0;
                }
            }

            &--active{
                #{$block-name}__link{
                    color: $white;
                }
            }
        }

        #{$block-name}__link{
            font-size: 11px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.33px;
            padding: 12px;
            color: $gray;

            @include min($screen-xl){
                font-size: 17px;
                letter-spacing: -0.68px;
            }
        }

        #{$block-name}__content-wrapper{
            padding: 0;

            @include min($screen-md){
                padding: 52px 0 0;
            }

            @include min($screen-xl){
                padding: 128px 0 0;
            }

        }

        #{$block-name}__content-item {

            .js & {
                display: block;

                @include min($screen-md){
                    display: none;
                }
            }

            &--active {

                .js & {
                    display: block;
                }
            }
        }
    }

    &--dark{
        #{$block-name}__link{
            color: $gray-lighter;
        }

        #{$block-name}__content-item{
            color: $gray-lighter;
        }
    }

    &--vertical{
        display: grid;
        grid-template-columns: 30% 70%;

        #{$block-name}__links{
            display: block;
        }

        #{$block-name}__link-wrap{
            &--active{
                #{$block-name}__link{
                    color: $gray-lighter;
                }
            }
        }

        #{$block-name}__link{
            color: $gray;
            font-size: 18px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.36px;
            padding: 12px 0;

            @include min($screen-xl){
                font-size: 30px;
                font-weight: 500;
                line-height: 96%;
                letter-spacing: -1.2px;
                padding: 18px 0;
            }

            &:hover {
                color: #fff;
            }
        }

        #{$block-name}__content-wrapper{
            padding: 8px 0 0;

            @include min($screen-xl){
                padding: 16px 0 0;
            }
        }
    }
}
