.features{
    $block-name: &; // #{$block-name}__element

    padding: 56px 0;

    @include min($screen-md){
        padding: 64px 0;
    }

    @include min($screen-xl){
        padding: 100px 0;
    }

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
        }

        @include min($screen-xl){
            grid-gap: 0;
            padding: 1px 0 0 1px;
        }
    }

    &__item{
        padding: 24px 16px;
        border: 1px solid $gray-light;

        @include min($screen-md){
            padding: 32px 24px;
        }

        @include min($screen-xl){
            padding: 40px 32px 48px;
            margin: -1px -1px 0 0;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 24px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }
    }

    .main-clients__bottom {
        margin-top: 4rem;
        .btn {
            width: 100%;
        }

        @include min($screen-md){
            margin-top: 4.8rem;
            .btn {
                width: auto;
            }
        }
        @include min($screen-xl){
            margin-top: 5.6rem;
        }
    }

}
