.blog-popular{
    $block-name: &; // #{$block-name}__element

    background-color: $gray-lighter;
    padding: 24px 16px 0;

    margin-bottom: 24px;

    @include min($screen-md){
        padding: 32px 16px 16px;
    }

    @include min($screen-xl){
        padding: 32px 24px;
    }

    &__title{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 20px;
        }

        @include min($screen-xl){
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
            margin-bottom: 32px;
        }
    }

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 48px;
        }

        @include min($screen-xl){
            grid-template-columns: 1fr;
        }
    }

    &__link{
        display: flex;
        align-items: center;
        gap: 12px;

        @include min($screen-md){
            gap: 16px;
        }
    }

    &__img{
        flex-shrink: 0;
        width: 70px;
        height: 70px;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__tag{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        color: $blue;
        margin-bottom: 8px;
    }

    &__text{
        font-size: 13px;
        font-weight: 500;
        line-height: 110%;
        letter-spacing: -0.26px;
        color: $black;

        @include min($screen-md){
            font-size: 14px;
            letter-spacing: -0.28px;
        }

        @include min($screen-xl){
            font-size: 15px;
            letter-spacing: -0.3px;
        }
    }
}
