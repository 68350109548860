.about-img{
    $block-name: &; // #{$block-name}__element

    height: 320px;

    @include min($screen-md){
        height: 400px;
    }

    @include min($screen-xl){
        height: 760px;
    }

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        //object-position: bottom;
    }
}
