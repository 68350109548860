.faq{
    $block-name: &; // #{$block-name}__element

    @include min($screen-xl){
        width: 755px;
    }

    .ac{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $gray-lighter;
        color: $gray-lighter;
        margin-top: 0;

        &:last-child{
            border-bottom: none;
        }

        //@include min($screen-md){
        //    border-bottom: none;
        //}


    }

    //.ac .ac-header{
    //    @include min($screen-md){
    //        display: none;
    //    }
    //}

    .ac .ac-trigger{
        font-family: $hoves;
        color: $gray-lighter;
        padding-left: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.16px;

        @include min($screen-md){
            padding-top: 24px;
            padding-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 19px;
            line-height: 120%;
            letter-spacing: -0.19px;
            padding-top: 36px;
            padding-bottom: 36px;
        }
    }

    .ac .ac-trigger::after{
        content: '';
        background-image: svg-load('cross.svg', color=$gray-lighter);
        background-size: contain;
        transform-origin: center;
        transition: $transition-time;
        width: 15px;
        height: 15px;
    }
    .ac.is-active > .ac-header .ac-trigger::after{
        content: '';
        transform: translate(0, -50%) rotate(45deg);
    }

    //.ac .ac-panel{
    //    @include min($screen-md){
    //        overflow: visible;
    //    }
    //}

    .ac .ac-panel .ac-text{
        padding: 0 0 16px;
        font-family: $hoves;

        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        color: $gray-lighter;

        a { color: #fff; text-decoration: underline; }
        a:hover { text-decoration: none; }

        @include min($screen-md){
            padding-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 16px;
            letter-spacing: -0.16px;
            max-width: 640px;
            padding-bottom: 36px;
        }

        p + p{
            padding-top: 13px;

        }


        //#{$block-name}__text{
        //    font-size: 13px;
        //    font-weight: 400;
        //    letter-spacing: -0.13px;
        //    color: $gray;
        //    line-height: 1.2;
        //
        //    @include min($screen-md){
        //        font-size: 32px;
        //        font-weight: 500;
        //        line-height: 0.96;
        //        letter-spacing: -0.64px;
        //        color: $white;
        //        max-width: 510px;
        //        padding-bottom: 24px;
        //    }
        //
        //    @include min($screen-xl){
        //        font-size: 54px;
        //        font-weight: 400;
        //        letter-spacing: -0.54px;
        //        max-width: 785px;
        //    }
        //}

        //#{$block-name}__tag{
        //    color: $white;
        //    font-size: 11px;
        //    font-weight: 500;
        //    line-height: 1;
        //    letter-spacing: -0.33px;
        //    background-color: $black-light;
        //    padding: 10px 8px;
        //
        //    @include min($screen-xl){
        //        font-size: 16px;
        //        line-height: 0.87;
        //        letter-spacing: -0.48px;
        //        padding: 12px;
        //
        //
        //    }
        //}

        //#{$block-name}__link{
        //    display: inline-block;
        //    padding-top: 20px;
        //    font-size: 12px;
        //    font-weight: 500;
        //    line-height: 1;
        //    letter-spacing: -0.36px;
        //    color: $white;
        //    text-transform: lowercase;
        //
        //    @include min($screen-md){
        //        display: none;
        //        //font-size: 11px;
        //        //text-transform: initial;
        //        //letter-spacing: -0.33px;
        //        //padding: 16px 24px;
        //        //border: 1px solid $white;
        //        //margin-top: 40px;
        //    }
        //
        //    @include min($screen-xl){
        //        //margin-top: 64px;
        //        //font-size: 16px;
        //        //line-height: 1.18;
        //        //text-transform: lowercase;
        //    }
        //
        //    span{
        //        @include min($screen-md){
        //            display: none;
        //        }
        //    }
        //}

        //#{$block-name}__btn{
        //    display: none;
        //
        //    @include min($screen-md){
        //        display: inline-flex;
        //        margin-top: 40px;
        //    }
        //
        //    @include min($screen-xl){
        //        margin-top: 64px;
        //    }
        //}
    }
}
