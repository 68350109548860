.burger {
    $block-name: &; // #{$block-name}__element

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    //width: 20px;
    border: none;
    padding: 0;
    //height: 24px;
    background: transparent;
    user-select: none;
    cursor: pointer;

    @include min($screen-md){
        //width: 24px;
        //height: 32px;
    }

    &__text{
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        margin-left: 10px;
    }

    &__icon{
        position: relative;
        display: flex;
        align-items: center;
        width: 33px;
        height: 33px;

        span { // stylelint-disable-line  selector-combinator-blacklist
            display: inline-block;
            position: relative;
            //width: 100%;
            //height: $toggler-part-height;
            //background: transparent;
            vertical-align: middle;
            width: 28px;
            left: 5px;
            font-size: 0;
            color: transparent;
            //background: $gray-lighter;
            transition: background $transition-time $transition-time;

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                width: 100%;
                height: $toggler-part-height;
                background: $gray-lighter;
                transform-origin: 50% 50%;
                transition: top $transition-time $transition-time, transform $transition-time;
            }

            &:before {
                top: -3px;
                left: -5px;

                @include min($screen-md){
                    top: -3px;
                }
            }

            &:after {
                top: 3px;

                @include min($screen-md){
                    top: 3px;
                }
            }
        }
    }

    &--close {

        #{$block-name}__icon{
            span { // stylelint-disable-line  selector-combinator-blacklist
                transition: background $transition-time 0s;
                background: transparent;

                &:before,
                &:after {
                    top: 0;
                    left: 0;
                    width: 20px;
                    transition: top $transition-time, transform $transition-time $transition-time;
                }

                &:before {
                    transform: rotate3d(0, 0, 1, 45deg);
                }

                &:after {
                    transform: rotate3d(0, 0, 1, -45deg);
                }
            }
        }
    }
}
