.field-num {

    $block-name: &; // #{$block-name}__element

    display: block;

    &__name {
        //@include field-name;
    }

    &__input-wrap {
        display: block;
    }

    &__input-and-btns {
        display: flex;
        align-items: center;
        position: relative;
        //width: 160px;
        //padding-left: 40px;
        //padding-right: 40px;
    }

    &__input {
        //@include field-text;
        //padding: $field-padding-vertical 45px;
        min-height: 35px;
        max-width: 78px;
        padding: 5px 16px;
        background-color: $white;
        border: none;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        line-height: 1.3;
        border-radius: 8px;

        &::placeholder{
            //color: $black-60;
        }

        &:focus{
            //border-color: darken($uazure, 10%);
            box-shadow: none;
        }


        #{$block-name}:not(#{$block-name}--disabled) button:focus ~ & { // stylelint-disable-line
            //@include field-focus;
        }
    }

    &__btn {
        //position: absolute;
        //top: 1px;
        //bottom: 1px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        border: 1px solid $black;
        border-radius: 10px;
        padding: 0;
        //border-top-right-radius: $border-radius;
        //border-bottom-right-radius: $border-radius;
        font: 0/0 a; // stylelint-disable-line
        //color: transparent;
        background: transparent;

        &:hover,
        &:focus {
            //background-color: $gray-lightest;
            cursor: pointer;
        }

        &:focus {
            outline: none;
        }

        //&--plus {
        //    right: 1px;
        //    background: svg-load('plus.svg', color=$black) center no-repeat;
        //    background-size: 20px 20px;
        //
        //    &.disabled{
        //        background: svg-load('plus.svg', color=rgba($black, 0.5)) center no-repeat;
        //        background-size: 20px 20px;
        //    }
        //}
        //
        //&--minus {
        //    left: 1px;
        //    background: svg-load('minus.svg', color=$black) center no-repeat;
        //    background-size: 20px 20px;
        //
        //    &.disabled{
        //        background: svg-load('minus.svg', color=rgba($black, 0.5)) center no-repeat;
        //        background-size: 20px 20px;
        //    }
        //}

        #{$block-name}--disabled & {
            cursor: not-allowed;

            &:hover,
            &:focus {
                background-color: transparent;
            }
        }

        &--disabled{
            //color: $uazure;
            pointer-events: none;
        }
    }

    &__help-text {
        //@include field-help-text;
    }


    &--error {

        #{$block-name}__name,
        #{$block-name}__input,
        #{$block-name}__help-text {
            //color: $color-danger;
        }

        #{$block-name}__input {
            //border-color: $color-danger;
            //background-color: lighten($color-danger, 35%);
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}
