@font-face {
    font-family: 'TT Hoves Pro Trl Exp';
    src: url('../fonts/TTHovesProTrlExp-Md.woff2') format('woff2'),
    url('../fonts/TTHovesProTrlExp-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Pro Trl Exp';
    src: url('../fonts/TTHovesProTrlExp-DmBd.woff2') format('woff2'),
    url('../fonts/TTHovesProTrlExp-DmBd.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TT Hoves Pro Trl Exp';
    src: url('../fonts/TTHovesProTrlExp-Rg.woff2') format('woff2'),
    url('../fonts/TTHovesProTrlExp-Rg.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



