.education-feature{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-gap: 16px;

        @include min($screen-md){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 32px 100px;
            padding-left: 160px;
        }

        @include min($screen-xl){
            grid-gap: 64px 110px;
            padding-left: 230px;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 12px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }

        br{
            display: none;

            @include min($screen-xl){
                display: inline;
            }
        }
    }
}
