.main-platforms{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 1px 0 0 1px;

        @include min($screen-md){
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__item{
        position: relative;
        margin: -1px -1px 0 0;
        border: 1px solid $gray-light;

        &:before{
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    &__link{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20%;

        @include min($screen-md){
            padding: 30%;
        }

    }

    &__img{
        flex-grow: 1;
        object-fit: contain;
        margin-bottom: 10px;
    }

    &__text{
        color: $black;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        min-height: 40px;

        @include min($screen-xl){
            font-size: 17px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.68px;
        }
    }
}
