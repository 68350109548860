.hero-list{
    $block-name: &; // #{$block-name}__element

    padding-bottom: 37px;

    @include min($screen-md){
        padding-bottom: 48px;
    }

    @include min($screen-xl){
        padding-bottom: 100px;
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 40px;

        @include min($screen-md){
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 48px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 400;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 56px;
        }
    }

    &__item{
        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 50px;
            padding-top: 32px;
            padding-bottom: 32px;
        }

        @include min($screen-xl){
            padding-top: 36px;
            padding-bottom: 36px;
        }

        &:first-child{
            padding-top: 0;
        }

        &:last-child{
            padding-bottom: 0;
        }
    }

    &__link{
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.36px;
        text-transform: lowercase;
        color: $gray-lighter;
        margin-top: 20px;

        @include min($screen-md){
            font-size: 11px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.33px;
            margin-top: 16px;
        }

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 118%;
            letter-spacing: -0.32px;
            text-transform: lowercase;
            margin-top: 24px;
        }

        &:hover, &:focus-visible{
            color: $blue-light;
        }

        &--desktop{
            display: none;

            @include min($screen-md){
                display: inline-block;
            }
        }

        &--mobile{
            @include min($screen-md){
                display: none;
            }
        }
    }



    .ac{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $black-light;
        color: $gray-lighter;
        margin-top: 0;

        &:last-child{
            border-bottom: none;
        }

        //@include min($screen-md){
        //    border-bottom: none;
        //}
    }

    .ac .ac-trigger{
        font-family: $hoves;
        font-size: 17px;
        font-weight: 500;
        color: $gray-lighter;
        letter-spacing: -0.34px;
        padding-left: 0;
        padding-top: 20px;
        padding-bottom: 20px;

        @include min($screen-md){
            display: flex;
            flex-direction: column;
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            padding: 0;
        }

        @include min($screen-xl){
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    .ac .ac-trigger::after{
        content: '';
        background-image: svg-load('cross.svg', color=$gray-lighter);
        background-size: contain;
        transform-origin: center;
        transition: $transition-time;
        width: 15px;
        height: 15px;

        @include min($screen-md){
            display: none;
        }
    }
    .ac.is-active > .ac-header .ac-trigger::after{
        content: '';
        transform: translate(0, -50%) rotate(45deg);
    }

    .ac .ac-panel .ac-text{
        padding: 0 0 24px;
        font-family: $hoves;

        @include min($screen-md){
            padding: 0;
        }

        &#{$block-name}__descr{
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.13px;
            line-height: 1.2;
            color: $gray;

            @include min($screen-xl){
                font-size: 19px;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.19px;
            }

            p{
                &:first-child{
                    @include min($screen-md){
                        color: $gray-lighter;
                    }
                }
            }

            p + p{
                margin-top: 10px;
            }
        }
    }
}
