.hero-services{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    background-color: $black;
    color: $gray-lighter;
    padding-top: 300px;
    margin-bottom: 50px;

    //background-image: url(../img/pic-1.png), url(../img/pic-1.png);
    //background-repeat: no-repeat, no-repeat;
    //background-origin: border-box;
    ////background-position: 15% -35%, 80% 160%;
    //background-position: 300px -35%, 80% 160%;
    //background-size: 570px auto;

    @include min($screen-md){
        padding-top: 325px;
    }

    //&:before, &:after{
    //    content: '';
    //    position: absolute;
    //    z-index: 0;
    //    background-image: url(../img/pic-1.png);
    //    background-repeat: no-repeat;
    //    background-origin: border-box;
    //    width: 570px;
    //    height: 570px;
    //    background-size: contain;
    //
    //    @include min($screen-md){
    //        width: 820px;
    //        height: 820px;
    //    }
    //
    //    @include min($screen-xl){
    //        width: 1000px;
    //        height: 1000px;
    //    }
    //}
    //
    //&:before{
    //    right: -200px;
    //    top: -200px;
    //
    //    @include min($screen-md){
    //        right: -300px;
    //        top: -300px;
    //    }
    //
    //    @include min($screen-xl){
    //        right: -300px;
    //        top: -370px;
    //    }
    //}
    //
    //&:after{
    //    left: -270px;
    //    bottom: -270px;
    //    transform: rotate(-40deg);
    //
    //    @include min($screen-md){
    //        left: -80px;
    //        bottom: -500px;
    //        transform: rotate(-265deg);
    //    }
    //}

    &__container{
        position: relative;
        z-index: 1;
    }

    &__name{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        color: $blue-light;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.24px;
        }
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 76px;

        @include min($screen-md){
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 84px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 400;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 120px;
        }
    }
}
