.hero-education{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    background-color: $gray-lighter;
    //color: $gray-lighter;
    padding-top: 170px;
    padding-bottom: 20px;

    @include min($screen-md){
        padding-bottom: 24px;
    }


    @include min($screen-xl){
        padding-top: 200px;
        padding-bottom: 40px;
    }

    &__name{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        color: $blue-light;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.24px;
        }
    }

    &__name-gray{
        color: $gray;

        &:after{
            content: '/';
            margin-left: 5px;
            margin-right: 5px;
        }

        &:hover, &:focus-visible{
            color: $blue-light;
        }
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 12px;

        @include min($screen-md){
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 16px;
            max-width: 577px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 400;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 24px;
            max-width: 1070px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }
    }
}
