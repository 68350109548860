.vacancies-expert{
    $block-name: &; // #{$block-name}__element

    overflow: hidden;

    &__top{
        display: flex;
        align-items: flex-end;
        margin-bottom: 24px;
        gap: 12px;

        @include min($screen-md){

            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 56px;
        }
    }

    &__title{
        margin: 0;
    }

    &__navigation{
        flex-shrink: 0;
        margin-left: auto;
        display: flex;
        gap: 12px;
    }

    &__wrapper{
        position: relative;
    }

    &__slider{
        overflow: visible;
    }

    .swiper-slide{
        &:last-child{
            #{$block-name}__slide{
                &:after{
                    display: none;
                }
            }
        }
    }

    &__slide{
        padding-top: 24px;

        @include min($screen-md){
            padding-top: 43px;
        }

        &:before{
            content: '';
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 8px;
            height: 8px;
            background-color: $blue;
            //margin-bottom: 16px;

            @include min($screen-md){
                width: 11px;
                height: 11px;
                //margin-bottom: 32px;
            }
        }

        &:after{
            content: '';
            position: absolute;
            z-index: 0;
            width: calc(100% + 24px);
            height: 1px;
            top: 3.5px;
            left: 0;
            background-color: $gray;

            @include min($screen-md){
                top: 5px;
                width: calc(100% + 60px);
            }
        }
    }

    &__slide-title{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__slide-text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-xl){
            font-size: 16px;
            letter-spacing: -0.16px;
        }
    }
}
