.event-form{
    $block-name: &; // #{$block-name}__element

    //background-image: url(../img/contacts/bg2.svg);
    //background-repeat: no-repeat;
    //background-size: 300% auto;
    //background-color: $blue;
    //background-position: 5% 100%;
    padding-top: 56px;
    padding-bottom: 56px;

    @include min($screen-md){
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 0.96;
        letter-spacing: -0.56px;
        margin-bottom: 40px;
        color: $black;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 30px;
            letter-spacing: -1.2px;
            margin-bottom: 48px;
        }
    }

    &__wrapper{
        display: grid;

        @include min($screen-md){
            grid-template-columns: 1.1fr 0.9fr;
        }

        @include min($screen-xl){
            grid-template-columns: 1fr 1fr;
        }
    }

    &__form{
        background-color: $gray-lighter;
        padding: 56px 20px 40px;
        margin-bottom: 40px;
        margin-right: -20px;
        margin-left: -20px;


        @include min($screen-md){
            margin: 0;
            padding: 40px 15px 40px 24px;
        }

        @include min($screen-xl){
            padding: 56px;
        }
    }

    &__checkbox{
        margin-bottom: 40px;

        @include min($screen-md){
            margin-bottom: 48px;
        }

        @include min($screen-xl){
            margin-bottom: 56px;
        }
    }

    &__btns{
        display: flex;
    }

    &__btn{
        width: 100%;

        @include min($screen-md){
            width: auto;
        }
    }

    &__descr{

        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;

        background-color: $blue;
        background-image: url(../img/contacts/bg2.svg);
        background-repeat: no-repeat;
        background-size: 300% auto;
        background-position: 50% 50%;


        @include min($screen-xl){
            background-size: 200% auto;
            padding-left: 60px;
        }
    }

    &__label{
        background-color: $white;
        padding: 16px 20px;


        @include min($screen-md){
            padding: 16px;
            max-width: 520px;
        }

        @include min($screen-xl){
            padding: 32px;

        }
    }

    &__label-title{
        font-size: 16px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.16px;
        margin-bottom: 10px;
    }

    &__label-text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 12px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-bottom: 24px;
        }
    }

    &__label-link{
        display: inline-block;
        color: $black;
        font-size: 16px;
        font-weight: 400;
        line-height: 118%;
        letter-spacing: -0.32px;
        text-transform: lowercase;
        margin-bottom: 16px;

        @include min($screen-xl){
            margin-bottom: 24px;
        }
    }

    &__bottom{
        display: flex;
        align-items: center;
    }

    &__bottom-img{
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin-right: 12px;

        @include min($screen-md){
            width: 35px;
            height: 35px;
            margin-right: 8px;
        }

        @include min($screen-xl){
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__name{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        margin-bottom: 4px;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.16px;
        }
    }

    &__post{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        color: $black-light;
        opacity: 0.57;

        @include min($screen-md){
            font-size: 9px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -0.27px;
        }

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 500;
            line-height: 0.87;
            letter-spacing: -0.48px;
        }
    }

    &__img{
        margin-right: -20px;
        margin-left: -20px;

        @include min($screen-md){
            margin-right: 0;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &--img{
        #{$block-name}__wrapper{
            @include min($screen-xl){
                grid-template-columns: 735px 1fr;
            }
        }

        #{$block-name}__title{
            color: $gray-lighter;
        }

        #{$block-name}__form{
            background-color: $blue;
            padding: 40px 20px;

            @include min($screen-md){
                padding: 32px 24px;
            }

            @include min($screen-xl){
                padding: 48px;
            }
        }
    }
}
