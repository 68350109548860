.swiper-button-next, .swiper-button-prev{
    width: 30px;
    height: 30px;
    //top: 0;
    position: static;

    &:after{
        font-family: $hoves;
        font-size: 0;
        width: 22px;
        height: 12px;
        transition: background $transition-time;
    }
}

.swiper-button-prev{
    //left: auto;
    //right: 40px;

    &:hover, &:focus-visible{
        &:after{
            background-image: svg-load('slider-prev.svg', color=darken($gray, 15%));
        }
    }

    &:after{
        background-image: svg-load('slider-prev.svg', color=$gray);
    }
}

.swiper-button-next{
    //left: auto;
    //right: 0;

    &:hover, &:focus-visible{
        &:after{
            background-image: svg-load('slider-next.svg', color=darken($gray, 15%));
        }
    }

    &:after{
        background-image: svg-load('slider-next.svg', color=$gray);
    }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom, .swiper-pagination-fraction{
    --swiper-pagination-bullet-horizontal-gap: 6px;
    bottom: 0;
}

.swiper-pagination-bullet-active{
    background: $red;
}
