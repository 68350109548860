.blog-item{
    $block-name: &; // #{$block-name}__element

    &__link{
        color: $black;
    }

    &__img{
        position: relative;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 20px;
        }

        &:before{
            content: '';
            display: block;
            padding-top: 50%;

            @include min($screen-xl){
                padding-top: 45%;
            }
        }

        img{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
        }
    }

    &__title{
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.3px;
        margin-bottom: 8px;

        @include min($screen-md){
            font-size: 18px;
            letter-spacing: -0.36px;
            margin-bottom: 16px;
        }

        @include min($screen-xl){
            font-size: 20px;
            line-height: 110%;
            letter-spacing: -0.4px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        color: $gray;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 24px;
        }
    }

    &__bottom{
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__tag, &__date{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
    }

    &__tag{
        color: $blue;
    }
}
