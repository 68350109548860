.vacancies-list{
    $block-name: &; // #{$block-name}__element

    &__list{
        display: grid;
        grid-gap: 16px;
        margin-bottom: 48px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
        }

        @include min($screen-xl){
            margin-bottom: 40px;
        }
    }

    &__item{
        background-color: $gray-lighter;
        padding: 24px 16px;

        @include min($screen-md){
            padding: 32px 24px;
        }

        @include min($screen-xl){
            padding: 40px 32px;
        }
    }

    &__tag{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        margin-bottom: 12px;
        color: $gray;

        @include min($screen-xl){
            font-size: 16px;
            line-height: 87.018%;
            letter-spacing: -0.48px;
        }
    }

    &__subtitle{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 16px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-bottom: 32px;
        }
    }

    .btn__up{
        padding: 16px 20px;

        @include min($screen-xl){
            padding: 15px 32px;
        }
    }
}
