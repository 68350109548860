.vacancies-worker{
    $block-name: &; // #{$block-name}__element

    &__title{
        margin: 0;

        @include min($screen-md){
            max-width: 520px;
        }

        @include min($screen-xl){
            max-width: 100%;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-top: 12px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            margin-top: 16px;
            max-width: 610px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-top: 24px;
            max-width: 710px;
            margin-bottom: 56px;
        }
    }

    &__images{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include min($screen-xl){
            grid-template-columns: 1fr 1fr 2fr;
        }
    }

    &__img{
        height: 130px;

        @include min($screen-md){
            height: 200px;
        }

        @include min($screen-xl){
            height: 300px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &--last{
            display: none;

            @include min($screen-md){
                display: block;
            }
        }
    }
}
