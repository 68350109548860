.contacts-page{
    $block-name: &; // #{$block-name}__element

    overflow: hidden;

    padding-top: 107px;

    @include min($screen-md){
        padding-top: 140px;
    }

    @include min($screen-xl){
        padding-top: 200px;
    }

    &__wrapper{
        display: grid;
        grid-gap: 60px;
        margin-bottom: 40px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 16px;
            margin-bottom: 60px;
        }

        @include min($screen-xl){
            margin-bottom: 80px;
        }
    }

    &__title{
        @include min($screen-md){
            margin-top: 24px;
        }

        @include min($screen-xl){
            margin-top: 36px;
        }
    }

    &__item{
        margin-bottom: 16px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 120px 1fr;
            grid-gap: 16px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            display: grid;
            grid-template-columns: 145px 1fr;
            grid-gap: 36px;
            margin-bottom: 36px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__left{
        font-size: 13px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.26px;
        margin-bottom: 12px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.32px;
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 19px;
            line-height: 120%;
            letter-spacing: -0.19px;
        }
    }

    &__right{
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }

        .btn--copy{
            color: $blue-light;
        }

        a{
            position: relative;
            color: $blue-light;

            &:after{
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 1px;
                background-color: $blue-light;
                transition: width $transition-time ease-out;
            }

            &:hover, &:focus-visible{
                &:after{
                    width: 100%;
                }
            }
        }
    }

    &__form{
        background-color: $gray-lighter;
        margin-left: -20px;
        margin-right: -20px;
        padding: 40px 20px;

        @include min($screen-md){
            margin: 0;
            padding: 32px 24px;
        }

        @include min($screen-xl){
            padding: 56px;
        }
    }

    &__form-title{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 48px;
        }
    }

    &__checkbox{
        margin-bottom: 24px;

        @include min($screen-xl){
            margin-bottom: 48px;
        }
    }

    &__map{
        position: relative;

        &:before{
            content: '';
            display: block;
            padding-top: 58%;

            @include min($screen-md){
                padding-top: 51%;
            }

            @include min($screen-xl){
                padding-top: 45%;
            }
        }

        iframe{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
