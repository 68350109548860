.main-tabs{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    display: grid;
    height: 100%;
    background-color: $black;
    color: $white;
    padding-top: 36px;
    padding-bottom: 36px;

    @include min($screen-md){
        padding-top: 22px;
        padding-bottom: 22px;
        min-height: 405px;
    }

    @include min($screen-xl){
        padding-top: 28px;
        padding-bottom: 28px;
        min-height: 720px;
    }

    &__video{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;

        video{
            position: absolute;
            top: -150px;
            right: -250px;
            //width: 950px;
            //height: auto;
            width: 500px;
            height: auto;
            transform: rotate(15deg);

            @include min($screen-md){
                top: auto;
                right: 0;
                bottom: 0;
                width: 500px;
                transform: rotate(15deg) translate(170px, 100px);
            }

            @include min($screen-xl){
                width: 950px;
                transform: rotate(15deg) translate(300px, 200px);
            }
        }
    }

    //&__canvas-wrap{
    //    position: relative;
    //    top: 0;
    //    left: 0;
    //    height: 100%;
    //    width: 100%;
    //    z-index: 0;
    //}

    &__canvas{
        position: absolute;
        top: -150px;
        right: -250px;
        width: 500px;
        height: 500px;

        @include min($screen-md){
            top: auto;
            right: -100px;
            bottom: -100px;
        }

        @include min($screen-xl){
            width: 950px;
            height: 950px;
            bottom: -200px;
            right: -300px;
        }

        canvas{
            //position: absolute;
            //width: 100%;
            //height: 100%;
            //top: 0;
            //left: 0;
        }
    }

    &__container{
        position: relative;
        z-index: 3;
        overflow: hidden;
    }

    &__tags{
        display: none;
        flex-wrap: wrap;
        gap: 8px;

        @include min($screen-md){
            display: flex;
        }
    }

    /* Accordion style */
    .ac{
        background-color: transparent;
        border: none;
        border-bottom: 1px solid $black-light;
        color: $gray-lighter;
        margin-top: 0;

        &:last-child{
            border-bottom: none;
        }

        @include min($screen-md){
            border-bottom: none;
        }
    }

    .ac .ac-header{
        @include min($screen-md){
            display: none;
        }
    }

    .ac .ac-trigger{
        font-family: $hoves;
        font-size: 17px;
        font-weight: 500;
        color: $gray-lighter;
        letter-spacing: -0.34px;
        padding-left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .ac .ac-trigger::after{
        content: '';
        background-image: svg-load('cross.svg', color=$gray-lighter);
        background-size: contain;
        transform-origin: center;
        transition: $transition-time;
        width: 15px;
        height: 15px;
    }
    .ac.is-active > .ac-header .ac-trigger::after{
        content: '';
        transform: translate(0, -50%) rotate(45deg);
    }

    .ac .ac-panel{
        @include min($screen-md){
            overflow: visible;
        }
    }

    .ac .ac-panel .ac-text{
        padding: 0 0 24px;
        font-family: $hoves;


        #{$block-name}__text{
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.13px;
            color: $gray;
            line-height: 1.2;

            @include min($screen-md){
                font-size: 32px;
                font-weight: 500;
                line-height: 0.96;
                letter-spacing: -0.64px;
                color: $white;
                max-width: 510px;
                padding-bottom: 24px;
            }

            @include min($screen-xl){
                font-size: 54px;
                font-weight: 400;
                letter-spacing: -0.54px;
                max-width: 785px;
            }
        }

        #{$block-name}__tag{
            color: $white;
            font-size: 11px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.33px;
            background-color: $black-light;
            padding: 10px 8px;

            @include min($screen-xl){
                font-size: 16px;
                line-height: 0.87;
                letter-spacing: -0.48px;
                padding: 12px;


            }
        }

        #{$block-name}__link{
            display: inline-block;
            padding-top: 20px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.36px;
            color: $white;
            text-transform: lowercase;

            @include min($screen-md){
                display: none;
                //font-size: 11px;
                //text-transform: initial;
                //letter-spacing: -0.33px;
                //padding: 16px 24px;
                //border: 1px solid $white;
                //margin-top: 40px;
            }

            @include min($screen-xl){
                //margin-top: 64px;
                //font-size: 16px;
                //line-height: 1.18;
                //text-transform: lowercase;
            }

            span{
                @include min($screen-md){
                    display: none;
                }
            }
        }

        #{$block-name}__btn{
            display: none;

            @include min($screen-md){
                display: inline-flex;
                margin-top: 40px;
            }

            @include min($screen-xl){
                margin-top: 64px;
            }
        }
    }
    /* end accordion style */
}
