.vacancies-office{
    $block-name: &; // #{$block-name}__element

    &__top{
        margin-bottom: 24px;

        @include min($screen-md){
            display: flex;
            align-items: flex-end;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 56px;
        }
    }

    &__title{
        margin: 0;

        @include min($screen-md){
            max-width: 520px;
        }

        @include min($screen-xl){
            max-width: 820px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-top: 12px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            margin-top: 16px;
            max-width: 590px;
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-top: 24px;
            max-width: 770px;
        }
    }

    &__navigation{
        flex-shrink: 0;
        margin-left: auto;
        display: flex;
        gap: 12px;
    }

    &__slider{
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            margin-bottom: 20px;
        }
    }

    &__wrapper{
        position: relative;
    }

    &__slide{
        position: relative;

        &:before{
            content: '';
            display: block;
            padding-top: 78.5%;

            @include min($screen-md){
                padding-top: 40%;
            }

            @include min($screen-xl){
                padding-top: 45%;
            }
        }

        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__pagination{
        position: static;
    }
}
