.hero-blog{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    background-color: $white;
    padding-top: 100px;

    @include min($screen-xl){
        padding-top: 200px;
    }

    &__container{
        position: relative;
        z-index: 1;
    }

    &__name{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        color: $blue-light;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        gap: 16px;

        @include min($screen-md){
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            margin-bottom: 40px;
            gap: 24px;
        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.24px;
            gap: 40px;
        }
    }

    &__name-date{
        color: $gray;
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 76px;

        @include min($screen-md){
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 84px;
            max-width: 580px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 120px;
            max-width: 1045px;
        }
    }

    &__item{
        position: relative;
        background-color: $gray-lighter;

        &:hover, &:focus-visible{

            #{$block-name}__up{
                background-color: $blue;
                transform: translate(-8px, -8px);
            }

            #{$block-name}__down{
                border: 1px solid $blue;
            }

            #{$block-name}__line{
                opacity: 1;
                transition: opacity 0.25s ease-in-out;
            }

            #{$block-name}__subtitle,
            #{$block-name}__text,
            #{$block-name}__tag,
            #{$block-name}__date{
                color: $gray-lighter;
            }
        }
    }

    &__up{
        position: relative;
        z-index: 1;
        width: 100%;
        transition: $transition-time;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 16px;
        }

        @include min($screen-xl){
            grid-column-gap: 36px;
        }
    }

    &__down{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
        transition: border $transition-time ease-in-out;
    }

    &__line{
        opacity: 0;
        transition: opacity 0.05s ease-in-out;

        &--first{
            display: block;
            width: 12px;
            height: 1px;
            position: absolute;
            left: -9px;
            bottom: 4px;
            transform: rotate(45deg);
            background-color: $blue;
        }

        &--second {
            display: block;
            width: 12px;
            height: 1px;
            position: absolute;
            right: -1px;
            bottom: 5px;
            transform: rotate(45deg);
            background-color: $blue;
        }

        &--third{
            display: block;
            width: 12px;
            height: 1px;
            position: absolute;
            right: -1px;
            top: -4px;
            transform: rotate(45deg);
            background-color: $blue;
        }
    }

    &__img{
        height: 140px;

        @include min($screen-md){
            height: 260px;
        }

        @include min($screen-xl){
            height: 340px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__descr{
        padding: 16px;

        @include min($screen-md){
            padding: 24px 24px 24px 0;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        @include min($screen-xl){
            padding: 32px 32px 32px 0;
        }
    }

    &__subtitle{
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.3px;
        margin-bottom: 8px;
        transition: $transition-time;

        @include min($screen-md){
            font-size: 18px;
            letter-spacing: -0.36px;
            max-width: 250px;
        }

        @include min($screen-xl){
            font-size: 24px;
            line-height: 110%;
            letter-spacing: -0.48px;
            margin-bottom: 16px;
            max-width: 420px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 24px;
        transition: $transition-time;

        @include min($screen-md){
            max-width: 470px;
        }

        @include min($screen-xl){
            font-size: 16px;
            letter-spacing: -0.16px;
        }
    }

    &__item-bottom{
        margin-top: auto;
        display: flex;
        gap: 24px;
    }

    &__tag, &__date{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        transition: $transition-time;

        @include min($screen-xl){
            font-size: 16px;
            line-height: 87.018%;
            letter-spacing: -0.48px;
        }
    }

    &__tag{
        color: $blue;
    }

    &--article{
        #{$block-name}__title{
            margin-bottom: 24px;

            @include min($screen-md){
                margin-bottom: 32px;
            }

            @include min($screen-xl){
                margin-bottom: 56px;
            }
        }
    }
}
