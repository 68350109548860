.contacts{
    $block-name: &; // #{$block-name}__element

    background-image: url(../img/contacts/bg2.svg);
    background-repeat: no-repeat;
    background-size: 300% auto;
    background-color: $blue;
    background-position: 5% 100%;
    padding-top: 56px;
    padding-bottom: 56px;

    @include min($screen-md){
        background: none;
        padding-top: 0;
        padding-bottom: 0;
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 0.96;
        letter-spacing: -0.56px;
        margin-bottom: 40px;
        color: $gray-lighter;

        @include min($screen-md){
            font-size: 32px;
            letter-spacing: -0.64px;
            margin-bottom: 48px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 400;
            letter-spacing: -0.54px;
        }
    }

    &__wrapper{
        display: grid;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
        }
    }

    &__form{
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;


        @include min($screen-md){
            background-color: $blue;
            padding-left: 24px;
            padding-right: 43px;
            padding-top: 64px;
            padding-bottom: 64px;
            margin-bottom: 0;
        }

        @include min($screen-xl){
            padding-left: calc((100vw - 1242px)/ 2);
            padding-right: 110px;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    &__textarea{
        margin-top: 32px;

        @include min($screen-xl){
            margin-top: 72px;
        }
    }

    &__checkbox{
        margin-bottom: 40px;

        @include min($screen-md){
            margin-bottom: 48px;
        }

        @include min($screen-xl){
            margin-bottom: 56px;
        }
    }

    &__btns{
        display: flex;
    }

    &__btns-file{
        display: none;

        @include min($screen-xl){
            display: block;
            max-width: 215px;
            width: 100%;
            margin-right: 24px;
        }
    }
    &__btns-agreement {
        margin: 10px 0px 0px 0px;
        font-size: 14px;
        color: #aaa;

        a {
            color: #fff;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__descr{

        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;



        @include min($screen-md){
            background-color: $black;
            background-image: url(../img/contacts/bg2.svg);
            background-repeat: no-repeat;
            background-size: 300% auto;
            background-position: 50% 50%;
        }

        @include min($screen-xl){
            background-size: 200% auto;
            padding-left: 60px;
        }
    }

    &__label{
        background-color: $white;
        padding: 16px 20px;


        @include min($screen-md){
            padding: 16px;
            max-width: 520px;
        }

        @include min($screen-xl){
            padding: 32px;

        }
    }

    &__label-title{
        font-size: 16px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.16px;
        margin-bottom: 10px;
    }

    &__label-text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 16px;

        @include min($screen-md){
            margin-bottom: 12px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-bottom: 24px;
        }
    }

    &__label-link{
        display: inline-block;
        color: $black;
        font-size: 16px;
        font-weight: 400;
        line-height: 118%;
        letter-spacing: -0.32px;
        text-transform: lowercase;
        margin-bottom: 16px;

        @include min($screen-xl){
            margin-bottom: 24px;
        }
    }

    &__bottom{
        display: flex;
        align-items: center;
    }

    &__bottom-img{
        flex-shrink: 0;
        width: 30px;
        height: 30px;
        margin-right: 12px;

        @include min($screen-md){
            width: 35px;
            height: 35px;
            margin-right: 8px;
        }

        @include min($screen-xl){
            width: 50px;
            height: 50px;
            margin-right: 16px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    &__name{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        margin-bottom: 4px;

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: -0.16px;
        }
    }

    &__post{
        font-size: 11px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: -0.33px;
        color: $black-light;
        opacity: 0.57;

        @include min($screen-md){
            font-size: 9px;
            font-weight: 500;
            line-height: 0.96;
            letter-spacing: -0.27px;
        }

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 500;
            line-height: 0.87;
            letter-spacing: -0.48px;
        }
    }

    &--modal{
        display: grid;
        align-items: center;

        background-image: url(../img/contacts/bg2.svg);
        background-repeat: no-repeat;
        background-size: 150% auto;
        background-color: $black;
        background-position: -40% 50%;
        height: 100%;

        padding: 20px;

        @include min($screen-md){
            padding: 24px;
        }

        @include min($screen-xl){
            padding: 100px 70px;
        }

        #{$block-name}__wrapper{
            grid-column-gap: 24px;

            @include min($screen-md){
                grid-column-gap: 32px;
            }

            @include min($screen-xl){
                grid-column-gap: 72px;
            }
        }

        #{$block-name}__form{
            padding: 0;
            //padding-left: 24px;
            background-color: transparent;
        }

        #{$block-name}__descr{
            padding: 0;
            background-color: transparent;
            background-image: none;
        }

        #{$block-name}__title{
            @include min($screen-xl){
                font-size: 50px;
            }
        }
    }

    &--faq{

        #{$block-name}__title{
            margin-bottom: 24px;

            @include min($screen-md){
                font-size: 26px;
                font-weight: 500;
                line-height: 96%;
                letter-spacing: -0.52px;
                margin-bottom: 16px;
            }

            @include min($screen-xl){
                font-size: 50px;
                line-height: 94%;
                letter-spacing: -2.5px;
                margin-bottom: 20px;
            }
        }

        #{$block-name}__wrapper{
            @include min($screen-xl){
                //grid-template-columns: 1fr 1fr;
            }
        }

        #{$block-name}__descr{
            @include min($screen-xl){
                padding-left: 42px;
            }
        }

        #{$block-name}__descr-wrap{
            @include min($screen-xl){
                max-width: 330px;
            }
        }

        #{$block-name}__label-text{
            font-size: 13px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.13px;
            margin-bottom: 12px;

            @include min($screen-xl){
                font-size: 16px;
                letter-spacing: -0.16px;
                margin-bottom: 16px;
            }
        }
    }

    &--vacancy{
        background-image: none;

        #{$block-name}__form{
            margin-bottom: 0;
        }

        #{$block-name}__descr{
            display: none;
            padding: 0;
            background-image: none;

            @include min($screen-md){
                display: block;
            }
        }

        #{$block-name}__image{
            height: 100%;
            width: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        #{$block-name}__btns{
            display: flex;
            flex-direction: column;

            @include min($screen-md){
                flex-direction: row;
                gap: 16px;
            }

            .btn{
                width: 100%;

                @include min($screen-xl){
                    min-width: 215px;
                }
            }

            .btn__up{
                @include min($screen-md){
                    padding: 17px 32px;
                }

                @include min($screen-xl){
                    padding: 20px 32px;
                }
            }
        }

        //#{$block-name}__btns-file{
        //    display: block;
        //    margin-bottom: 12px;
        //    margin-right: 0;
        //
        //    @include min($screen-md){
        //        margin-bottom: 0;
        //    }
        //}
    }
}
