// Common styles of the project

html {
    font-size: 10px;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    height: 100%;

    @include max($screen-md){
        overflow-x: hidden;
    }
}

.page {
    //scroll-behavior: smooth;
    font-family: $hoves;
    font-size: 16px;
    //text-rendering: optimizeSpeed;
    line-height: 1.2;
    font-weight: 400;
    //background-color: $body-bg;
    display: flex;
    flex-direction: column;
    //min-height: 100vh;
    height: 100%;
    //color: $black-color;
    background-color: $black;
    //background-color: #c0bbbb;
}

.page-wrapper{
    display: flex;
    flex-direction: column;
}

.page__header{
    &--main{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }
}

.page__header-fixed{
    $block-name: &; // #{$block-name}__element

    flex: 0 0 auto;


    position: fixed;
    width: 100%;
    //background-color: $white-color;
    //background-color: $gray;
    //height: 70px;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateY(-100%);
}

.page__header{
    flex: 0 0 auto;
}

.page__main{
    position: relative;
    flex: 1 0 auto;
    background-color: $gray-lighter;

    &--white{
        background-color: $white;
    }
}
.page__footer{
    flex: 0 0 auto;
}

* html .page__wrapper {
    height: 100%;
}

.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

img {
    max-width: 100%;
    display: block;
    vertical-align: middle;
}

::placeholder {
    //color: rgba($text-color, 0.5);
}

ul,
ol {
    padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

ul,
ol {
    list-style: none;
}

a:not([class]) {
    text-decoration-skip-ink: auto;
}

article > * + * {
    margin-top: 1em;
}

input,
button,
textarea,
select {
    font: inherit;
}

@media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

a{
    //color: $orange-color;
    text-decoration: none;
    transition: color $transition-time;

    &:hover, &:focus-visible{
        //color: $blue;
    }

    &:focus{
        outline: none;
    }
}

.container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;

    @include min($screen-md){
        padding: 0 24px;
    }
}

.js-focus-visible:focus:not(.focus-visible) {
    outline: none;
}

.visually-hidden {
    position: absolute;
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.title{
    font-size: 21px;
    font-weight: 500;
    line-height: 0.97;
    letter-spacing: -0.42px;
    margin-bottom: 40px;

    @include min($screen-md){
        font-size: 32px;
        font-weight: 500;
        line-height: 0.96;
        letter-spacing: -0.64px;
        margin-bottom: 48px;
    }

    @include min($screen-xl){
        font-size: 50px;
        font-weight: 500;
        line-height: 0.94;
        letter-spacing: -2.5px;
        margin-bottom: 56px;
    }
}

.lil-gui.root > .title{
    font-size: 12px;
    letter-spacing: 1px;
    margin: 0;
}


.section{
    margin-bottom: 56px;

    @include min($screen-md){
        margin-bottom: 60px;
    }

    @include min($screen-xl){
        margin-bottom: 100px;
    }
}

.section-two{
    margin-bottom: 80px;

    @include min($screen-xl){
        margin-bottom: 140px;
    }
}


.custom-hover{
    $block-name: &; // #{$block-name}__element

    position: relative;

    &__hover{
        position: absolute;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $red;
        visibility: hidden;
        opacity: 0;
        transition: $transition-time;
    }

    &__line{
        opacity: 0;
        transition: opacity 0.1s ease-in-out;

        &--first{
            display: none;

            @include min($screen-xl){
                display: block;
                width: 34px;
                height: 2px;
                background-color: $red;
                position: absolute;
                left: -4px;
                bottom: -12px;
                transform: rotate(45deg);
            }
        }

        &--second{
            display: none;

            @include min($screen-xl){
                display: block;
                width: 34px;
                height: 2px;
                background-color: $red;
                position: absolute;
                right: -28px;
                bottom: -12px;
                transform: rotate(45deg);
            }
        }

        &--third{
            display: none;

            @include min($screen-xl){
                display: block;
                width: 34px;
                height: 2px;
                background-color: $red;
                position: absolute;
                right: -28px;
                top: 12px;
                transform: rotate(45deg);
            }
        }
    }

    &:hover, &:focus-visible{

        .custom-hover__hover{
            visibility: visible;
            opacity: 1;

            @include min($screen-xl){
                transform: translate(-25px, -25px);
            }

        }

        .custom-hover__line{
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }
}
