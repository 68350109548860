.field-checkbox {

    $block-name: &; // #{$block-name}__element

    display: block;
    //margin-bottom: $typo-margin-vertical;

    &__title {
        @include field-name;
    }

    &__input-wrap {

        & + & {
            //margin-top: $typo-margin-vertical;
        }

        &--error {

            #{$block-name}__name-text,
            #{$block-name}__help-text {
                color: $red;
            }
        }

        &--quantity{
            padding-top: 10px;
            padding-bottom: 10px;

            #{$block-name}__name-text{
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
        }

        &--grey{
            #{$block-name}__name-text{
                color: $gray;

                a{
                    color: $gray;
                }

                &:before {
                    border: 1px solid $gray;
                }

                //@at-root input:focus ~ &:before {
                //    @include field-focus;
                //}

                @at-root input:disabled ~ &:before {
                    border-color: $gray;
                    background: $gray;
                }

                &:after {
                    border-left: 1px solid $gray;
                    border-bottom: 1px solid $gray;

                    @at-root input:checked:disabled ~ & {
                        border-color: $gray-light;
                    }
                }
            }
        }
    }

    &__name {
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;
        padding-left: 28px;
        //margin-right: 0.6em;
        font-weight: 400;
        //min-height: 30px;
    }

    &__name-text {
        display: inline-block;
        color: $gray-light;
        font-size: 13px;
        line-height: 1.3;

        @include min($screen-xl){
            font-size: 16px;
        }

        a{
            color: $gray-light;
            text-decoration: underline;

            &:hover, &:focus-visible{
                text-decoration: none;
            }
        }

        // свой чекбокс с картинкой

        // &:before {
        //   content: '';
        //   position: absolute;
        //   top: 5px;
        //   left: 0;
        //   width: 14px;
        //   height: 14px;
        //   background-image: svg-load('../blocks/field-checkbox/bg-img/checkbox.svg');
        //   background-size: 100%;
        // }
        // @at-root input:checked ~ &:before {
        //   background-image: svg-load('../blocks/field-checkbox/bg-img/checkbox--checked.svg');
        // }

        // / свой чекбокс с картинкой

        // свой чекбокс без картинок

        @at-root input:disabled ~ & {
            cursor: not-allowed;
        }

        &:before {
            content: '';
            position: absolute;
            //top: 50%;
            //transform: translateY(-50%);
            top: 1px;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid $gray-light;

            @include min($screen-xl){
                top: 2px;
            }
        }

        //@at-root input:focus ~ &:before {
        //    @include field-focus;
        //}

        @at-root input:disabled ~ &:before {
            border-color: $gray-light;
            background: $gray-lighter;
        }

        &:after {
            content: '';
            position: absolute;
            top: 7px;
            left: 4px;
            width: 10px;
            height: 4px;
            opacity: 0;
            border-left: 1px solid $gray-light;
            border-bottom: 1px solid $gray-light;
            transform: translateY(-50%) rotate(-45deg);
            transition: opacity $transition-time;
            //box-shadow: 1px 2px 0 #fff, inset 0 -2px 0 #fff;

            @include min($screen-xl){
                top: 8px;
            }

            @at-root input:checked ~ & {
                opacity: 1;
            }

            @at-root input:checked:disabled ~ & {
                border-color: $gray-light;
            }
        }

        // / свой чекбокс без картинок
    }

    &__text{
        font-size: 16px;
        color: $black;
    }

    &__num{
        min-width: 25px;
        text-align: right;
        font-size: 12px;
        color: $gray;
    }

    &__input {
        position: absolute;
        top: 0.7em;
        left: 0;
        margin: 0;
        padding: 0;
        transform: translateY(-50%);

        &:focus,
        &:active {
            @include field-focus;
        }

        // сокрытие инпута в случае использования своего чекбокса
        opacity: 0;
    }

    &__help-text-wrap {
        padding-left: 1.5em;
    }

    &__help-text {
        @include field-help-text;
    }
}
