.vacancies-study{
    $block-name: &; // #{$block-name}__element

    overflow: hidden;

    &__title{
        margin: 0;
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-top: 12px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            margin-top: 16px;
            max-width: 515px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            margin-top: 24px;
            max-width: 690px;
            margin-bottom: 56px;
        }
    }

    &__form-title{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 48px;
        }
    }

    &__checkbox{
        margin-bottom: 24px;

        @include min($screen-xl){
            margin-bottom: 48px;
        }
    }

    &__wrapper{
        display: grid;
        grid-gap: 20px;

        @include min($screen-md){
            grid-template-columns: 1fr 1fr;
            grid-gap: 0;
        }
    }

    &__image{
        @include min($screen-md){
            order: 2;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__form{
        background-color: $gray-lighter;
        margin-left: -20px;
        margin-right: -20px;
        padding: 40px 20px;

        @include min($screen-md){
            order: 1;
            margin: 0;
            padding: 40px 24px;
        }

        @include min($screen-xl){
            padding: 56px;
        }
    }

    &__textarea{
        margin-top: 32px;

        @include min($screen-xl){
            margin-top: 48px;
        }
    }

    &__btns{
        display: flex;
        flex-direction: column;

        @include min($screen-md){
            flex-direction: row;
            gap: 16px;
        }

        .btn{
            width: 100%;

            @include min($screen-xl){
                min-width: 215px;
            }
        }

        .btn__up{
            @include min($screen-md){
                padding: 17px 32px;
            }

            @include min($screen-xl){
                padding: 20px 32px;
            }
        }
    }

    &__btns-file{
        margin-bottom: 12px;
        display: none;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            display: block;
        }
    }

}
