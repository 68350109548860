.article{
    $block-name: &; // #{$block-name}__element

    h2{
        font-size: 21px;
        font-weight: 500;
        line-height: 97%;
        letter-spacing: -0.42px;
        margin: 60px 0 24px;

        @include min($screen-md){
            font-size: 32px;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-top: 80px;
        }

        @include min($screen-xl){
            font-size: 40px;
            line-height: 94%;
            letter-spacing: -1.6px;
            margin-bottom: 40px;
        }

        &:first-child{
            margin-top: 0;
        }
    }

    h3{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin: 32px 0 24px;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin: 44px 0 28px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin: 65px 0 32px;
        }

        &:first-child{
            margin-top: 0;
        }
    }

    h4{
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.3px;
        margin-bottom: 40px;

        @include min($screen-md){
            font-size: 18px;
            letter-spacing: -0.36px;
        }

        @include min($screen-xl){
            font-size: 24px;
            line-height: 120%;
            letter-spacing: -0.24px;
            margin-bottom: 56px;
        }
    }

    p{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            line-height: 130%;
            letter-spacing: -0.19px;
        }
    }

    p + p{
        margin-top: 15px;

        @include min($screen-md){
            margin-top: 20px;
        }

        @include min($screen-xl){
            margin-top: 24px;
        }
    }

    blockquote{
        font-size: 15px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.3px;
        background-color: $gray-lighter;
        padding: 16px;
        margin: 24px 0;

        @include min($screen-md){
            font-size: 18px;
            letter-spacing: -0.36px;
            padding: 32px 24px;
            max-width: 520px;
        }

        @include min($screen-xl){
            font-size: 22px;
            font-weight: 400;
            line-height: 130%;
            letter-spacing: -0.22px;
            max-width: 100%;
            margin: 40px 0;
        }
    }

    figure{
        margin: 24px 0;

        @include min($screen-md){
            margin: 48px 0;
        }
    }

    figcaption{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        margin-top: 24px;
        text-align: center;
        color: $gray;

        @include min($screen-md){
            font-size: 13px;
            line-height: 120%;
            letter-spacing: -0.13px;
        }

        @include min($screen-xl){
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.16px;
        }
    }

    ul{
        margin: 32px 0;

        @include min($screen-md){
            margin: 40px 0;
        }

        li{
            position: relative;

            &:before{
                content: '';
                position: absolute;
                left: 0;
                top: 3px;
                background-color: $blue;
                width: 8px;
                height: 8px;

                @include min($screen-md){
                    width: 12px;
                    height: 12px;
                    top: 5px;
                }
            }
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    ol{
        margin: 32px 0;

        @include min($screen-md){
            margin: 40px 0;
        }

        li{
            counter-increment: step-counter;
            position: relative;

            &:before{
                content: counter(step-counter);
                position: absolute;
                left: 0;
                top: 0;
                color: $blue;
                //top: 3px;

                @include min($screen-md){
                    //top: 5px;
                }
            }
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    li{
        padding-left: 20px;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 24px;

        @include min($screen-md){
            padding-left: 28px;
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    a{
        color: $black;
        text-decoration: underline;

        &:hover, &:focus-visible{
            text-decoration: none;
        }
    }

    &--vacancy{
        ul{
            li{
                &:before{
                    @include min($screen-md){
                        top: 2px;
                    }

                    @include min($screen-xl){
                        top: 5px;
                    }
                }
            }
        }

        li{
            @include min($screen-md){
                font-size: 13px;
                letter-spacing: -0.13px;
            }

            @include min($screen-xl){
                font-size: 19px;
                letter-spacing: -0.19px;
            }
        }
    }
}

.text-name-block{
    background-color: $gray-lighter;
    padding: 16px;
    margin: 40px 0;

    @include min($screen-md){
        padding: 32px 24px;
    }

    @include min($screen-xl){
        padding: 40px 36px;
        margin: 48px 0;
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 16px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            line-height: 130%;
            letter-spacing: -0.19px;
            margin-bottom: 32px;
        }
    }

    &__bottom{
        display: flex;
        align-items: center;
        gap: 8px;

        @include min($screen-md){
            gap: 16px;
        }
    }

    &__img{
        flex-shrink: 0;
        width: 54px;
        height: 54px;
    }

    &__name{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }
    }

    &__post{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        opacity: 0.57;

        @include min($screen-xl){
            font-size: 16px;
            line-height: 87.018%;
            letter-spacing: -0.48px;
        }
    }
}


.list-block{
    margin: 24px 0;

    @include min($screen-md){
        margin: 32px 0;
    }

    @include min($screen-xl){
        margin: 40px 0;
    }

    &__item{
        margin-bottom: 16px;

        @include min($screen-md){
            display: grid;
            grid-template-columns: 120px 1fr;
            grid-gap: 16px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            grid-template-columns: 180px 1fr;
            grid-gap: 24px;
        }

        &:last-child{
            margin-bottom: 0;
        }
    }

    &__left{
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        letter-spacing: -0.16px;
        margin-bottom: 12px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
        }
    }

    &__right{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        @include min($screen-xl){
            font-size: 19px;
            line-height: 130%;
            letter-spacing: -0.19px;
        }
    }
}


.name-block{
    background-color: $gray-lighter;
    padding: 16px;
    margin: 40px 0;

    display: flex;
    align-items: center;
    gap: 8px;

    @include min($screen-md){
        padding: 32px 24px;
        gap: 16px;
        max-width: 375px;
    }

    @include min($screen-xl){
        padding: 32px;
        margin: 48px 0;
        max-width: 400px;
    }

    &__img{
        flex-shrink: 0;
        width: 54px;
        height: 54px;

        @include min($screen-md){
            width: 80px;
            height: 80px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__name{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
        }
    }

    &__post{
        font-size: 11px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.33px;
        opacity: 0.57;

        @include min($screen-xl){
            font-size: 16px;
            line-height: 87.018%;
            letter-spacing: -0.48px;
        }
    }
}

