.hero-site{
    $block-name: &; // #{$block-name}__element

    position: relative;
    overflow: hidden;
    background-color: $black;
    color: $gray-lighter;
    padding-top: 300px;
    padding-bottom: 61px;

    @include min($screen-md){
        padding-top: 325px;
        padding-bottom: 64px;
    }

    @include min($screen-xl){
        padding-bottom: 100px;
    }

    //&:before, &:after{
    //&:before{
    //    content: '';
    //    position: absolute;
    //    z-index: 0;
    //    background-image: url(../img/pic-1.png);
    //    background-repeat: no-repeat;
    //    background-origin: border-box;
    //    width: 570px;
    //    height: 570px;
    //    background-size: contain;
    //
    //    @include min($screen-md){
    //        width: 820px;
    //        height: 820px;
    //    }
    //
    //    @include min($screen-xl){
    //        width: 1000px;
    //        height: 1000px;
    //    }
    //}
    //
    //&:before{
    //    right: -200px;
    //    top: -200px;
    //
    //    @include min($screen-md){
    //        right: -300px;
    //        top: -300px;
    //    }
    //
    //    @include min($screen-xl){
    //        right: -300px;
    //        top: -370px;
    //    }
    //}

    &__container{
        position: relative;
        z-index: 1;
    }

    &__name{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        color: $blue-light;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 21px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.42px;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            font-size: 24px;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: -0.24px;
        }
    }

    &__name-gray{
        color: $gray;

        &:after{
            content: '/';
            margin-left: 5px;
            margin-right: 5px;
        }

        &:hover, &:focus-visible{
            color: $blue-light;
        }
    }

    &__title{
        font-size: 28px;
        font-weight: 500;
        line-height: 96%;
        letter-spacing: -0.56px;
        margin-bottom: 12px;

        @include min($screen-md){
            font-size: 32px;
            font-weight: 500;
            line-height: 96%;
            letter-spacing: -0.64px;
            margin-bottom: 16px;
            max-width: 577px;
        }

        @include min($screen-xl){
            font-size: 54px;
            font-weight: 400;
            line-height: 96%;
            letter-spacing: -0.54px;
            margin-bottom: 24px;
            max-width: 1070px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 76px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            max-width: 390px;
            margin-bottom: 88px;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            max-width: 540px;
            margin-bottom: 120px;
        }
        .contacts__btn { margin-top: 2rem;}
    }

    &__wrapper{
        //width: 606px;
        margin-left: 0;

        @include min($screen-md){
            width: 50%;
        }

        @include min($screen-xl){
            width: 606px;
        }
    }

    &__slide{
        @include min($screen-xl){
            display: flex;
            gap: 48px;
        }
    }

    &__slide-title{
        font-size: 17px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.34px;
        margin-bottom: 16px;
        flex-shrink: 0;

        @include min($screen-md){
            font-size: 21px;
            letter-spacing: -0.42px;
            margin-bottom: 24px;
        }

        @include min($screen-xl){
            font-size: 30px;
            line-height: 96%;
            letter-spacing: -1.2px;
            margin-bottom: 0;
        }
    }

    &__slide-text{
        flex-grow: 1;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        color: $gray;

        @include min($screen-xl){
            font-size: 16px;
            letter-spacing: -0.16px;
        }

        p + p{
            margin-top: 8px;

            @include min($screen-md){
                margin-top: 16px;
            }

            @include min($screen-xl){
                margin-top: 24px;
            }
        }
    }


    .swiper{
        @include min($screen-md){
            overflow: visible;
        }
    }

    .swiper-slide{
        width: 606px;
        color: $gray;
        //transition: width 0.3s;

        &:not(.swiper-slide-next):not(.swiper-slide-active){
            #{$block-name}__slide-title{
                @include min($screen-xl){
                    margin-left: -350px;
                }
            }
        }
    }

    .swiper-slide-active{
        //width: 606px;
        color: $gray-lighter;

        #{$block-name}__slide-text{
            visibility: visible;
            opacity: 1;
        }
    }

    &__pagination-wrap{
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    &__pagination{

        //flex: 1 1 1232px;
        position: relative;
        display: flex;
        gap: 100px;
        justify-content: space-between;
        margin-bottom: 40px;
        min-height: 60px;

        @include min($screen-md){
            min-height: 75px;
        }

        @include min($screen-xl){
            margin-bottom: 80px;
            min-height: 88px;
        }

        &.swiper-pagination-bullets.swiper-pagination-horizontal{
            width: 900px;

            @include min($screen-md){
                width: 1232px;
            }

        }

        &.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet{
            margin: 0;

            //.right{
            //    margin-left: auto;
            //}
        }


    }

    .swiper-pagination-bullet{
        position: relative;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        width: auto;
        height: auto;
        border-radius: 0;
        background-color: transparent;
        color: $gray;
        opacity: 1;
        //border-bottom: 1px solid $gray;
        //background-color: #fff;
        margin: 0;

        &:after{
            content: '';
            position: absolute;
            width: calc(100% + 100px);
            height: 1px;
            background-color: $gray;
            top: 40%;
            left: 0;
        }

        &:last-child{
            &:after{
                width: 100%;
            }
        }

        span{
            min-height: 29px;
            font-size: 11px;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.33px;


            @include min($screen-md){
                min-height: 38px;
                font-size: 13px;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -0.13px;
            }

            @include min($screen-xl){
                min-height: 45px;
                font-size: 16px;
                letter-spacing: -0.16px;
            }
        }
    }

    .swiper-pagination-bullet-active{
        color: $gray-lighter;

        &:after{
            background-color: $blue-light;
        }

        span{
            &:after{
                background-color: $blue-light;
            }
        }
    }

    .left, .bottom, .right{
        position: relative;

        &:after{
            content: '';
            position: absolute;
            width: 7px;
            height: 7px;
            background-color: $gray;
            left: 0;
            top: calc(40% - 3.5px);
        }
    }

    .bottom{
        max-width: 145px;
        text-align: center;
        margin-top: auto;

        &:after{
            left: 50%;
            top: -11px;
        }
    }

    .right{
        margin-left: auto;

        &:after{
            left: auto;
            right: 0;
        }
    }

    &__prev, &__next{
        display: none;

        @include min($screen-xl){
            display: flex;
            //margin-top: 48px;
        }
    }

    &__prev.swiper-button-prev{
        position: absolute;
        top: 100px;
        left: 0;

        &.swiper-button-disabled{
            display: none;
        }
    }


}
