.main-hero{
    $block-name: &; // #{$block-name}__element

    overflow: hidden;
    position: relative;
    height: 575px;


    @include min($screen-md){
        height: 500px;
    }

    @include min($screen-xl){
        height: 675px;
    }

    &__video{
        background-color: $blue;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;

        video{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__canvas{
        background-color: $blue;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        overflow: hidden;

        //video{
        //    width: 100%;
        //    height: 100%;
        //    object-fit: cover;
        //}
    }

    &__container{
        position: relative;
        z-index: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding-top: 55px;
        padding-bottom: 55px;

        @include min($screen-md){
            padding-left: 125px;
            padding-top: 65px;
            padding-bottom: 65px;
        }

        @include min($screen-xl){
            padding-left: 225px;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }

    &__title{
        font-size: 53px;
        font-weight: 400;
        color: $gray-lighter;
        margin-bottom: 16px;
        line-height: 0.86;
        letter-spacing: -2.65px;

        @include min($screen-md){
            font-size: 62px;
            margin-bottom: 24px;
            letter-spacing: -3.1px;
            line-height: 0.9;
        }

        @include min($screen-xl){
            font-size: 84px;
            line-height: 0.94;
            letter-spacing: -4.2px;
        }
    }

    &__text{
        font-size: 16px;
        color: $gray-lighter;
        margin-bottom: 24px;

        @include min($screen-md){
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 19px;
        }
    }

    &__btn{
        @include min($screen-md){
            display: none;
        }

        .btn__up{
            line-height: 1;
            font-size: 12px;
            padding: 20px 16px;
        }
    }
}
