:root{
    --mm-size: 100%;
    --mm-lineheight: 1;
    --mm-navbar-size: 60px;
}

.mobile-menu{
    $block-name: &; // #{$block-name}__element

    @include min($screen-md){
        display: none;
    }


    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__close{
        color: $gray-lighter;

        &--position{
            position: absolute;
            z-index: 2;
            right: 20px;
            top: -91px;
        }
    }

    &__list{
        //padding-top: 50px;
    }

    &__inner{
        padding-top: 40px;
    }

    &__footer{
        width: 100%;
        padding: 0 20px 20px;

        .btn{
            width: 100%;
        }
    }

    .nowrap{
        white-space: normal;
    }

    .mm-navbar__title > span{
        width: 100%;
    }

    &.mm-menu--theme-dark{
        --mm-color-background: #{$black};
        --mm-color-border: #{$black};
        --mm-color-text: #{$gray-lighter};
        --mm-color-text-dimmed: #{$gray-lighter};
        --mm-color-icon: #{$gray-lighter};
    }

    .mm-panel{
        background-image: url(../img/mobile-menu/bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 140% 0;
    }

    .mm-panel--opened[data-mm-parent]{
        background-position: 100% 0;
    }

    .mm-navbar{
        background-color: transparent;
        margin-bottom: 50px;
    }

    .mm-navbars--bottom{
        .mm-navbar{
            margin-bottom: 0;
        }
    }

    .mm-listitem__text{
        font-size: 21px;
        font-weight: 500;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .ac{
        background-color: transparent;
        border: none;
        margin-top: 0;
    }

    .ac .ac-trigger{
        font-family: $hoves;
        color: $gray;
        font-size: 21px;
        font-weight: 500;
        line-height: 97%;
        letter-spacing: -0.42px;
        padding: 8px 0;
    }

    .ac .ac-trigger::after{
        content: '';
        background-image: svg-load('cross.svg', color=$gray);
        background-size: contain;
        transform-origin: center;
        transition: $transition-time;
        width: 15px;
        height: 15px;
    }

    .ac .ac-panel .ac-text{
        padding: 16px 0;

        li{
            padding: 4px 0;
        }

        a{
            font-family: $hoves;
            color: $gray-lighter;
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.16px;
            white-space: normal;

            &:hover, &:focus-visible{
                color: $blue-light;
            }
        }
    }

    .ac.is-active {
        > .ac-header .ac-trigger::after {
            content: '';
            transform: translate(0, -50%) rotate(45deg);
            background-image: svg-load('cross.svg', color=$gray-lighter);
        }

        .ac-trigger{
            color: $gray-lighter;
        }
    }
}


