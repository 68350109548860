.vacancy-career{
    $block-name: &; // #{$block-name}__element

    &__top{
        margin-bottom: 32px;

        @include min($screen-md){
            display: flex;
            gap: 24px;
            align-items: flex-start;
            margin-bottom: 40px;
        }

        @include min($screen-xl){
            margin-bottom: 56px;
        }
    }

    &__title{
        margin-bottom: 16px;

        @include min($screen-xl){
            margin-bottom: 24px;
        }
    }

    &__text{
        font-size: 13px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.13px;
        margin-bottom: 24px;

        @include min($screen-md){
            font-size: 16px;
            letter-spacing: -0.16px;
            max-width: 480px;
            margin-bottom: 0;
        }

        @include min($screen-xl){
            font-size: 19px;
            letter-spacing: -0.19px;
            max-width: 520px;
        }
    }

    &__btn{
        margin-left: auto;
    }

    &__image{
        position: relative;

        &:before{
            content: '';
            display: block;
            padding-top: 58%;

            @include min($screen-md){
                padding-top: 39%;
            }

            @include min($screen-xl){
                padding-top: 45%;
            }
        }

        img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
